export function removeVietnameseDiacritics(str: string): string {
  const diacriticsMap: { [key: string]: string } = {
    a: 'á|ạ|ả|à|ã|â|ấ|ậ|ẩ|ầ|ẫ|ă|ắ|ặ|ẳ|ằ|ẵ',
    d: 'đ',
    e: 'é|ẹ|ẻ|è|ẽ|ê|ế|ệ|ể|ề|ễ',
    i: 'í|ị|ỉ|ì|ĩ',
    o: 'ó|ọ|ỏ|ò|õ|ô|ố|ộ|ổ|ồ|ỗ|ơ|ớ|ợ|ở|ờ|ỡ',
    u: 'ú|ụ|ủ|ù|ũ|ư|ứ|ự|ử|ừ|ữ',
    y: 'ý|ỵ|ỷ|ỳ|ỹ'
  }

  for (const nonDiacriticChar in diacriticsMap) {
    const diacriticChars = diacriticsMap[nonDiacriticChar]
    const regex = new RegExp(diacriticChars, 'gi')
    str = str?.replace(regex, nonDiacriticChar)
  }

  return str
}
export const validateInputSelectBox = () => {
  const arrInput: any = window.document.querySelectorAll('.selectBoxValidate input')
  if (arrInput?.length > 0) {
    arrInput.forEach((input) => {
      input.type = 'number'
      input.oninput = function () {
        if (this.value.length > 3) {
          this.value = this.value.slice(0, 3)
        }
      }
    })
  }
}
