import { Select, Space } from 'antd'
import CheckableTag from 'antd/es/tag/CheckableTag'
import { useAppSelector } from 'redux/store'
import { optionWorkTime } from '../type'
import ListMember from './ListMember'
import React, { useEffect, useState } from 'react'
type propsSwitchStaff = {
  listWorkTimeOptions: optionWorkTime[]
  salonSelect: number
  selectedTags?: optionWorkTime
  handleChangeStaff: (tag: optionWorkTime, checked: boolean) => void
  checkoutContainer: any
  form: any
  setSalonSwitch: (value: number) => void
  listDepartment: any
  salonSwitch: any
  isModalOpen?: boolean
}
function SwitchStaff({
  listWorkTimeOptions,
  salonSelect,
  selectedTags,
  handleChangeStaff,
  checkoutContainer,
  form,
  setSalonSwitch,
  listDepartment,
  salonSwitch,
  isModalOpen
}: propsSwitchStaff) {
  const { listStaffAll, allSalon }: any = useAppSelector((state) => state.checkin.resource)
  const [filterStaff, setFilterStaff] = useState([])
  const [listMemberFilter, setListMemberFilter] = useState<any>([])
  useEffect(() => {
    setListMemberFilter(
      filterStaff.length > 0 ? listStaffAll.filter((id) => filterStaff.includes(id.departmentId)) : listStaffAll
    )
  }, [filterStaff, listStaffAll, allSalon])
  return (
    <div>
      <div className='text_label'>chọn salon cần chuyển đến và ca chuyển</div>
      <Select
        className='select_style'
        allowClear
        options={allSalon.filter((item) => item?.id !== salonSelect)}
        onChange={(value) => setSalonSwitch(value)}
        placeholder='Chọn Salon'
        fieldNames={{ value: 'id', label: 'displayName' }}
        value={salonSwitch}
        showSearch
        optionFilterProp='children'
        filterOption={(input, option) =>
          ((option?.displayName as string) ?? '').toLowerCase().includes(input.toLowerCase())
        }
      />
      <Space className='switchEmployee_Space'>
        {listWorkTimeOptions.map((tag: optionWorkTime) => (
          <CheckableTag
            className='tag'
            key={tag.id}
            checked={selectedTags?.id === tag?.id}
            onChange={(checked) => handleChangeStaff(tag, checked)}
          >
            <div className='item_tag'>
              <span className='item_tag_top'>{tag.name}</span>
              <span className='item_tag_bottom'>{`${tag.startTime} - ${tag.endTime}`}</span>
            </div>
          </CheckableTag>
        ))}
      </Space>
      <Select
        mode='multiple'
        className='select_style'
        allowClear
        onChange={(value) => {
          setFilterStaff(value)
        }}
        showSearch={false}
        options={listDepartment}
        placeholder='Chọn tất cả bộ phận'
      />
      {salonSwitch && selectedTags && salonSelect && (
        <ListMember checkoutContainer={checkoutContainer} listMemberActive={listMemberFilter} form={form} />
      )}
    </div>
  )
}
export default React.memo(SwitchStaff)
