/* eslint-disable jsx-a11y/anchor-has-content */
import { Button, DatePicker, Form, Select, Spin } from 'antd'
import '../style/CheckinPage.scss'
import React, { useEffect, useState, useRef, useContext } from 'react'
import { ACTION_TIMEKEEPING, SELECT_GROUP_STEP_2, SELECT_STATUS_STEP_3, STAFF_SUPPORT } from '../constant'
import store, { useAppDispatch, useAppSelector } from 'redux/store'
import { useNavigate, useSearchParams } from 'react-router-dom'
import dayjs, { Dayjs } from 'dayjs'
import { handleFetchDepartment, handleFetchWorkTime } from '../hook/fetchData'
import { SearchOutlined, UpCircleFilled } from '@ant-design/icons'
import { getActionTimekeeping, getListTimeKeeping } from '../api'
import { handleFetchListTimekeeping } from '../hook/helper'
import { resetStore, setDefaultSalonWorkTime, setParamSearch } from '../reducers/checkin'
import Step3ListStaff from '../components/Step3ListStaff'
import { AppContext } from 'layouts/DashboardLayout'
import { antIcon } from '../components/FooterCheckin'
import { validateEditPermission } from '../util'

function ViewChecked() {
  const [searchParams] = useSearchParams()

  const { notification } = useContext(AppContext)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { resource, step1, paramSearch } = useAppSelector((state) => state.checkin)
  const { role, userInfo } = useAppSelector((state) => state.user)
  const checkinContainer = useRef<HTMLDivElement>()
  const count = useRef(0)
  const hasEditPermission = validateEditPermission(role, step1.date)

  const [loadingButton, setLoadingButton] = useState(false)

  const [form] = Form.useForm()

  const handleOnSearch = async (isRecall?: boolean) => {
    if (!paramSearch?.salon && userInfo?.listSalon.length > 1) {
      notification('error', 'Thất bại', 'Vui lòng chọn salon')
      return
    }
    try {
      const data = await getListTimeKeeping({
        salonId: userInfo?.listSalon.length === 1 ? userInfo?.listSalon[0].id : paramSearch.salon,
        workDate: paramSearch.date
      })
      dispatch(
        setDefaultSalonWorkTime({
          salon: userInfo?.listSalon.find(
            (i) => i.id === (userInfo?.listSalon.length === 1 ? userInfo?.listSalon[0].id : paramSearch.salon)
          ),
          date: paramSearch.date
        })
      )

      await handleFetchListTimekeeping(
        dispatch,
        step1.staff,
        resource.workTime,
        data,
        false,
        userInfo?.listSalon.length === 1 ? userInfo?.listSalon[0].id : paramSearch.salon,
        isRecall
      )
    } catch (error) {
      console.log(error)
      notification('error', 'Lỗi', 'Có lỗi xảy ra')
    }
  }

  const handleReCallApi = () => {
    const timer = setInterval(() => {
      if (count.current < 20) {
        const { listStaffChecked } = store.getState().checkin.resource
        const isPending = listStaffChecked.filter((i) => i.status === 'PENDING')
        if (isPending.length > 0 || count.current < 1) {
          handleOnSearch(true)
        }
        count.current = count.current + 1
      }
    }, 5000)
    return timer
  }
  const handleDisableButtonSubmit = () => {
    const { listStaffChecked } = store.getState().checkin.resource
    const result = listStaffChecked.filter((i) => i.isSupport === 0).length > 0

    return (
      paramSearch?.isSupport === 1 ||
      (paramSearch?.isSupport === 2 && step1.staff.length === 0) ||
      (!result && paramSearch.isSupport === 0)
    )
  }
  useEffect(() => {
    const time = { timer: undefined }
    if (resource.workTime.length > 0 && (paramSearch.salon || userInfo?.listSalon.length === 1)) {
      if (role === 'SM' && !searchParams.get('sync')) {
        handleOnSearch()
      } else if (searchParams.get('sync')) {
        handleOnSearch()
        time.timer = handleReCallApi()
      }
      if (!searchParams.get('sync') && role !== 'SM') {
        dispatch(resetStore())
      } else {
        handleOnSearch(true)
      }
    }
    handleFetchWorkTime(dispatch)
    handleFetchDepartment(dispatch)
    return () => clearInterval(time.timer)
  }, [resource.workTime])

  const disabledDate = (current: Dayjs) => {
    // Can not select days before today and today
    return current > dayjs().add(31, 'day').endOf('day')
  }

  return (
    <div className='checkout_container view_check' id='overflowHidden' ref={checkinContainer}>
      <Form form={form} name='dynamic_form_complex' style={{ paddingBottom: 100 }} autoComplete='off'>
        <div className='checkout_header'>
          <div className='checkout_header__title'>Đã chấm công</div>
        </div>
        <div className='checkout_content' style={{ padding: '70px 15px 20px 15px' }}>
          <div className='step2'>
            <div className='view-checked'>
              <DatePicker
                onChange={(_, dateString) => dispatch(setParamSearch({ ...paramSearch, date: dateString }))}
                defaultValue={dayjs(paramSearch.date)}
                className='select_style removeIcon'
                format={'YYYY-MM-DD'}
                allowClear={false}
                placeholder='Chọn ngày'
                disabledDate={disabledDate}
              />
              <Select
                onChange={(salon) => dispatch(setParamSearch({ ...paramSearch, salon }))}
                className='select_style'
                options={userInfo?.listSalon}
                disabled={userInfo?.listSalon.length === 1}
                fieldNames={{ value: 'id', label: 'salonName' }}
                placeholder='Chọn Salon'
                value={userInfo?.listSalon.length === 1 ? userInfo?.listSalon[0].id : paramSearch.salon}
                showSearch
                optionFilterProp='children'
                filterOption={(input, option) => (option?.salonName ?? '').toLowerCase().includes(input.toLowerCase())}
              />
              <Select
                className={`select_style ${paramSearch.department && 'activeIconClear'}`}
                allowClear
                options={resource.department.map((i) => ({
                  value: i.id,
                  label: i.displayName
                }))}
                onChange={(department) => {
                  dispatch(setParamSearch({ ...paramSearch, department }))
                }}
                value={paramSearch.department}
                placeholder='Chọn tất cả bộ phận'
              />
              <Select
                onChange={(workTime) => dispatch(setParamSearch({ ...paramSearch, workTime }))}
                className='select_style'
                options={SELECT_GROUP_STEP_2}
                value={paramSearch.workTime}
              />
              <Select
                allowClear
                className={`select_style ${paramSearch.status && 'activeIconClear'}`}
                options={SELECT_STATUS_STEP_3}
                onChange={(i) => {
                  dispatch(setParamSearch({ ...paramSearch, status: i }))
                }}
                value={paramSearch.status}
                placeholder='Trạng thái chấm'
              />
              <Select
                onChange={(i) => {
                  dispatch(setParamSearch({ ...paramSearch, isSupport: i }))
                  if (paramSearch.salon) handleOnSearch()
                }}
                className='select_style'
                options={STAFF_SUPPORT}
                placeholder='Chọn loại nhân viên'
                value={paramSearch.isSupport}
              />
              <Button
                disabled={loadingButton}
                onClick={async () => {
                  dispatch(resetStore())
                  navigate('/timekeeping/checked')
                  setLoadingButton(true)
                  await handleOnSearch()
                  setLoadingButton(false)
                  count.current = 21
                }}
                className='button_submit'
              >
                {loadingButton ? (
                  <Spin indicator={antIcon} />
                ) : (
                  <span>
                    <SearchOutlined /> Hiển thị nhân sự
                  </span>
                )}
              </Button>
            </div>
            {resource.listStaffChecked.length > 0 ? (
              <>
                {paramSearch.isSupport !== undefined && (
                  <div className='text_title'>{STAFF_SUPPORT[paramSearch.isSupport].label}</div>
                )}
                <Step3ListStaff
                  selectGroup={paramSearch.workTime?.label ? paramSearch.workTime?.value : paramSearch.workTime}
                  selectStatus={paramSearch.status}
                  selectedDepartment={paramSearch.department}
                  isSupport={paramSearch.isSupport}
                  form={form}
                  isFooter={false}
                  isEdit={false}
                />
              </>
            ) : (
              <div className='timekeeping_none'>{'Không có dữ liệu chấm công'}</div>
            )}
          </div>
        </div>
      </Form>
      <div className='checkout_footer'>
        <div className='wrap_content'>
          {resource.listStaffChecked.length > 0 && (
            <Button
              style={{ pointerEvents: loadingButton ? 'none' : 'auto' }}
              className='Button_Inactive'
              type='primary'
              onClick={() => {
                if (!hasEditPermission) {
                  notification(
                    'error',
                    'Thất bại',
                    `Bạn không có quyền chỉnh sửa thông tin chấm công ngày ${step1.date}`
                  )
                  return
                }
                navigate('/timekeeping/edit')
                localStorage.setItem('STAFF_EDIT', '')
              }}
              disabled={handleDisableButtonSubmit()}
            >
              Sửa
            </Button>
          )}
        </div>
      </div>
      <Button
        type='link'
        onClick={() => {
          checkinContainer.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        }}
        className='scrollToTop'
      >
        <UpCircleFilled style={{ fontSize: 25 }} />
      </Button>
    </div>
  )
}

export default React.memo(ViewChecked)
