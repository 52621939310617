import React, { useState, useEffect } from 'react'
import { Checkbox, Form, Select } from 'antd'
import { LIST_CHILD_GROUP, LIST_PARENT_GROUP } from '../constant'
import { useAppDispatch } from 'redux/store'
import { setListStaffAddStep2List, setSelectStaffAdd } from '../reducers/checkin'
import { useLocation } from 'react-router-dom'
import { StaffType } from '../type'
import { validateInputSelectBox } from 'utils/helpers/util'

type propsMember = {
  member: StaffType
  index: number
  form: any
  style?: any
}
const MemberComponent = ({ member, index, form, style }: propsMember) => {
  const editAgain = useLocation().pathname.includes('/timekeeping/edit')
  const step2 = useLocation().pathname === '/timekeeping/step-2'
  const step1 = useLocation().pathname === '/timekeeping/step-1'
  const dispatch = useAppDispatch()

  const [checked, setChecked] = useState(false)
  const [valueChild, setValueChild] = useState<number>()
  const [optionsChild, setOptionsChild] = useState<any[]>(
    LIST_CHILD_GROUP.reduce((a, b) => [...a, ...b]).map((i) => ({ label: i, value: i }))
  )

  useEffect(() => {
    validateInputSelectBox()
  }, [])
  return (
    <div
      key={member?.id}
      className='wrap_form_item'
      style={{ justifyContent: 'space-between', margin: '20px 0', ...style }}
    >
      <Form.Item name={[index, 'checkbox_group']} valuePropName='checked'>
        <Checkbox
          onChange={(e) => {
            const dataField = form.getFieldsValue()[`${member.id}`]
            const payload = {
              ...member,
              checkbox_group: e.target.checked,
              select_parent_group: dataField.select_parent_group,
              select_child_group: dataField.select_child_group
            }
            if (step1 || editAgain) {
              dispatch(setSelectStaffAdd(payload))
            }
            setChecked(e.target.checked)
            if (editAgain || step2) {
              dispatch(setListStaffAddStep2List(payload))
            }
          }}
          checked={checked}
        >
          <div className='wrap_form_item' style={{ cursor: 'pointer' }}>
            <div className='fullName_wrap'>
              <div className='fullName'>{member.fullName}</div>
              <div className='departmentName'>{`${member?.departmentName || 'không xác định'} - ${member.id}`}</div>
            </div>
          </div>
        </Checkbox>
      </Form.Item>
      {(member.departmentId === 1 || member.departmentId === 2) && (
        <div className='wrap_form_item'>
          <div className='select_parent_group'>
            <Form.Item style={{ marginBottom: 0 }} name={[index, 'select_parent_group']}>
              <Select
                // allowClear
                className='select_team_goup '
                onChange={(value) => {
                  const checkboxValue = form.getFieldsValue()[`${member.id}`].checkbox_group
                  const payload = {
                    ...member,
                    checkbox_group: !!value || checkboxValue,
                    select_parent_group: value,
                    select_child_group: null
                  }

                  form.setFieldsValue({
                    [member.id]: { select_child_group: null }
                  })
                  if (value) {
                    form.setFieldsValue({
                      [index]: { checkbox_group: true }
                    })
                    setOptionsChild(LIST_CHILD_GROUP[value].map((i) => ({ label: i, value: i })))
                  } else {
                    form.setFieldsValue({
                      [index]: { select_parent_group: undefined }
                    })
                    setOptionsChild(
                      LIST_CHILD_GROUP.reduce((a, b) => [...a, ...b]).map((i) => ({ label: i, value: i }))
                    )
                  }
                  if (step1) {
                    dispatch(setSelectStaffAdd(payload))
                  }
                  if (editAgain || step2) {
                    dispatch(setListStaffAddStep2List(payload))
                  }
                  setValueChild(null)
                }}
                options={LIST_PARENT_GROUP.map((item) => ({
                  label: item,
                  value: item
                }))}
              />
            </Form.Item>
            <div>Đội lớn</div>
          </div>
          <div className='select_child_group'>
            <Form.Item style={{ marginBottom: 0 }} name={[index, 'select_child_group']}>
              <Select
                // allowClear
                onChange={(value: number) => {
                  const checkboxValue = form.getFieldsValue()[`${member.id}`].checkbox_group
                  const payload = {
                    ...member,
                    checkbox_group: checkboxValue ? checkboxValue : !!value,
                    select_child_group: value,
                    select_parent_group: value
                      ? Number(value.toString()[0])
                      : form.getFieldsValue()[`${member.id}`].select_parent_group
                  }
                  if (step1) {
                    dispatch(setSelectStaffAdd(payload))
                  }
                  if (value) {
                    form.setFieldsValue({
                      [index]: {
                        checkbox_group: true,
                        select_parent_group: Number(value.toString()[0])
                      }
                    })
                  }
                  setValueChild(value)
                  if (editAgain || step2) {
                    dispatch(setListStaffAddStep2List(payload))
                  }
                }}
                className='select_team_goup selectBoxValidate'
                options={optionsChild}
                value={valueChild}
                showSearch
                optionFilterProp='children'
                filterOption={(input, option) =>
                  (option?.value.toString() ?? '').toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>

            <div>Đội nhỏ</div>
          </div>
        </div>
      )}
    </div>
  )
}
export default React.memo(MemberComponent)
