export const LIST_PARENT_GROUP = [1, 2, 3, 4, 5, 6, 7, 8, 9]
export const LIST_CHILD_GROUP = [
  [],
  [101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115],
  [201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215],
  [301, 302, 303, 304, 305, 306, 307, 308, 309, 310, 311, 312, 313, 314, 315],
  [401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412, 413, 414, 415],
  [501, 502, 503, 504, 505, 506, 507, 508, 509, 510, 511, 512, 513, 514, 515],
  [601, 602, 603, 604, 605, 606, 607, 608, 609, 610, 611, 612, 613, 614, 615],
  [701, 702, 703, 704, 705, 706, 707, 708, 709, 710, 711, 712, 713, 714, 715],
  [801, 802, 803, 804, 805, 806, 807, 808, 809, 810, 811, 812, 813, 814, 815],
  [901, 902, 903, 904, 905, 906, 907, 908, 909, 910, 911, 912, 913, 914, 915]
]
export const department = [
  { departmentId: 1, departmentName: 'Stylist' },
  { departmentId: 2, departmentName: 'Skinner' },
  { departmentId: 4, departmentName: 'Bảo vệ' },
  { departmentId: 5, departmentName: 'Supporter' },
  { departmentId: 6, departmentName: 'Checkout' },
  { departmentId: 8, departmentName: 'Bộ phận chung' },
  { departmentId: 9, departmentName: 'Trưởng salon (SM)' },
  { departmentId: 17, departmentName: 'Quản lý vùng (ASM)' },
  {
    departmentId: 36,
    departmentName: 'CTV'
  }
]
export const SELECT_GROUP_STEP_2 = [
  {
    label: 'DSCC theo ca',
    value: 0
  },
  {
    label: 'DSCC theo đội',
    value: 1
  }
]
export const SELECT_STATUS_STEP_3 = [
  {
    label: 'Đã chấm',
    value: 'SUCCESS'
  },
  {
    label: 'Lỗi',
    value: 'ERROR'
  },
  {
    label: 'Đang xử lý',
    value: 'PENDING'
  }
]
export const CHECKIN_STATUS = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
}
export const API_CHECKIN_STATUS = ['SUCCESS', 'PENDING', 'ERROR']
export const STAFF_SUPPORT = [
  {
    label: 'Nhân sự salon hiện tại',
    value: 0
  },
  {
    label: 'Nhân sự đi hỗ trợ',
    value: 1
  },
  {
    label: 'Nhân sự đến hỗ trợ',
    value: 2
  }
]
export const ACTION_TIMEKEEPING = {
  COPY: 'Timekeeping.Service.Copy',
  DELETE_ADMIN: 'Timekeeping.Service.Delete.Admin',
  DELETE_STAFF: 'Timekeeping.Service.Delete.Staff',
  UPDATE: 'Timekeeping.Service.Update',
  UPDATE_ADMIN: 'Timekeeping.Service.Update.Admin',
  VIEW_STAFF: 'TimeKeeping.ViewStaff',
  VIEW_RESOURCE: 'Timekeeping.ViewTimekeeping',
  ADD: 'Timekeeping.Service.Add',
  ADD_ADMIN: 'Timekeeping.Service.Add.Admin'
}
