import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { isEqualWith } from 'lodash'
import { SELECT_GROUP_STEP_2, STAFF_SUPPORT } from '../constant'
const StaffType = JSON.parse(localStorage.getItem('staff-type'))
const WorkTime = JSON.parse(localStorage.getItem('worktime'))
const filterUniquePairs = (inputArray: any) => {
  const uniquePairs = []
  const seenIds = new Set()

  for (const item of inputArray) {
    const { id, salonId } = item
    const pair = `${id}-${salonId}`

    if (!seenIds.has(pair)) {
      uniquePairs.push(item)
      seenIds.add(pair)
    }
  }

  return uniquePairs
}
export const innitState = {
  step1: {
    type: false,
    staff: [],
    date: dayjs().format('YYYY-MM-DD'),
    salon: 0,
    selectStaffAdd: []
  },
  resource: {
    listSalon: [],
    workTime: WorkTime ? WorkTime : [],
    listStaff: [],
    listStaffAll: [],
    idSalon_listStaffAll: undefined,
    listStaffChecked: [],
    department: StaffType ? StaffType : [],
    hourTimekeeping: {},
    allSalon: []
  },
  step2: {
    listStaffAdd: { list: [], workTime: undefined },
    listStaffEdit: []
  },
  paramSearch: {
    date: dayjs().format('YYYY-MM-DD'),
    salon: undefined,
    department: undefined,
    workTime: SELECT_GROUP_STEP_2[0],
    status: undefined,
    support: undefined,
    isSupport: STAFF_SUPPORT[0].value
  },
  selectedStaffIds: []
}
const checkinSlice = createSlice({
  name: 'checkin',
  initialState: innitState,
  reducers: {
    setStep1: (state, action) => {
      state.step1 = action.payload
    },
    setListStaffAddStep2List: (state, action) => {
      const currentState = JSON.parse(JSON.stringify(state))
      const result = currentState.step2.listStaffAdd.list
      const indexChange = result.findIndex((i) => i?.id === action.payload.id)
      if (indexChange !== -1) {
        result[indexChange] = action.payload
      } else result.push(action.payload)
      state.step2.listStaffAdd.list = result.filter((i) => i.checkbox_group)
    },
    setListStaffAddStep2WorkTime: (state, action) => {
      state.step2.listStaffAdd.workTime = action.payload
    },
    setListStaffEditStep2: (state, action) => {
      state.step2.listStaffEdit = action.payload
    },
    handleAddStaffStep2: (state, action) => {
      const currentState = JSON.parse(JSON.stringify(state))
      const result = currentState.step2.listStaffAdd.list.map((i) => ({
        ...i,
        time: action.payload,
        selectedCheckin: currentState.step2.listStaffAdd.workTime
      }))
      state.step1.staff = currentState.step1.staff.concat(result)
      state.step2.listStaffAdd.list = []
    },
    handleEditRemoveStaffStep2: (state) => {
      const currentState = JSON.parse(JSON.stringify(state))
      const store = localStorage.getItem('STAFF_EDIT')
      state.step1.staff = currentState.step1.staff.filter(
        (i) => !currentState.step2.listStaffEdit.includes(i.id.toString())
      )
      const resultStore =
        store && JSON.parse(store).filter((i) => !currentState.step2.listStaffEdit.includes(i.id.toString()))
      localStorage.setItem('STAFF_EDIT', JSON.stringify(resultStore?.length > 0 ? resultStore : ''))

      state.step2.listStaffEdit = []
    },
    handleEditStaffStep2: (state, action) => {
      const currentState = JSON.parse(JSON.stringify(state))
      const index = currentState.step1.staff.findIndex((i) => i.id === action.payload.id)
      state.step1.staff[index] = { ...action.payload }
    },
    fetchListDepartment: (state, action) => {
      state.resource.department = action.payload.sort((a, b) => a.id - b.id)
    },
    fetchListStaffTimekeeping: (state, action) => {
      const currentState = JSON.parse(JSON.stringify(state))
      if (!isEqualWith(currentState.resource.listStaff, action.payload)) {
        state.resource.listStaff = action.payload
      }
    },
    fetchListStaffAll: (state, action) => {
      const currentState = JSON.parse(JSON.stringify(state))
      if (!isEqualWith(currentState.resource.listStaffAll, action.payload)) {
        state.resource.listStaffAll = action.payload.list
        state.resource.idSalon_listStaffAll = action.payload.id
      }
    },
    fetchListStaffChecked: (state, action) => {
      const currentState = JSON.parse(JSON.stringify(state))
      const { listStaffChecked } = currentState.resource
      let result
      if (!isEqualWith(listStaffChecked, action.payload.data)) {
        if (action.payload?.isRecall) {
          result = action.payload.data.map((item) => {
            const check = listStaffChecked.find((i) => i.staffId === item.staffId && i.salonId === item.salonId)
            if (check)
              return {
                ...check,
                status: item.status,
                numberOfWorkHourOver: item.numberOfWorkHourOver,
                fullName: item.staffName
              }
            return item
          })
          state.resource.listStaffChecked = filterUniquePairs(result) || []
        } else state.resource.listStaffChecked = action.payload.data
      }
    },
    fetchListWorkTime: (state, action) => {
      const currentState = JSON.parse(JSON.stringify(state))
      if (!isEqualWith(currentState.resource.workTime, action.payload)) {
        state.resource.workTime = action.payload
      }
    },
    fetchListTimekeeping: (state, action) => {
      const currentState = JSON.parse(JSON.stringify(state))
      if (!isEqualWith(currentState.step1.staff, action.payload)) {
        state.step1.staff = action.payload
      }
    },
    fetchDetailTimekeeping: (state, action) => {
      const currentState = JSON.parse(JSON.stringify(state))
      const newData = currentState.resource.listStaffChecked.map((i, index) => {
        if (index === action.payload.index) return action.payload.time
        return i
      })
      state.resource.listStaffChecked = [...newData]
    },
    fetchDetailTimekeepingEdit: (state, action) => {
      const currentState = JSON.parse(JSON.stringify(state))
      const newData = currentState.step1.staff.map((i, index) => {
        if (index === action.payload.index) return action.payload.time
        return i
      })
      state.step1.staff = [...newData]
    },
    resetStore: (state) => {
      state.step1 = { ...innitState.step1 }
      state.resource.listStaffChecked = []
    },
    setDefaultSalonWorkTime: (state, action) => {
      state.step1.salon = action.payload.salon
      state.step1.date = action.payload.date
    },
    setParamSearch: (state, action) => {
      state.paramSearch = action.payload
    },
    fetchAllSalon: (state, action) => {
      state.resource.allSalon = action.payload
    },
    resetStaffSelect: (state) => {
      state.step2.listStaffAdd.list = []
      state.step2.listStaffEdit = []
    },
    setSelectStaffAdd: (state, action) => {
      const currentState = JSON.parse(JSON.stringify(state))
      const result = currentState.step1.selectStaffAdd
      const indexChange = result.findIndex((i) => i?.id === action.payload.id)
      if (indexChange !== -1) {
        result[indexChange] = action.payload
      } else result.push(action.payload)
      state.step1.selectStaffAdd = result
    },
    resetStaffSelectStep1: (state) => {
      state.step1.selectStaffAdd = []
    },
    resetTimeStaffStep1: (state) => {
      const currentState = JSON.parse(JSON.stringify(state))
      const result = currentState.step1.staff.map((item) => {
        const check = item
        delete check.time
        return check
      })
      state.step1.staff = result
    },
    updateChangeStaffFromStogare: (state) => {
      const currentState = JSON.parse(JSON.stringify(state))
      const store = localStorage.getItem('STAFF_EDIT') ? JSON.parse(localStorage.getItem('STAFF_EDIT')) : ''
      if (!store) return
      const { staff } = currentState.step1
      const resultData =
        store.length > 0
          ? staff.map((i) => (store.find((j) => j.id === i.id) ? store.find((j) => j.id === i.id) : i))
          : []
      state.step1.staff = resultData
      localStorage.setItem('STAFF_EDIT', '')
    },
    updateTeamGroupStaffEdit: (state, action) => {
      const currentState = JSON.parse(JSON.stringify(state))
      const result = currentState.step1.staff.map((i) => (i.id !== action.payload.id ? i : action.payload))
      state.step1.staff = result
    }
  }
})
const { actions, reducer } = checkinSlice
export const {
  setStep1,
  setListStaffAddStep2List,
  setListStaffAddStep2WorkTime,
  setListStaffEditStep2,
  handleAddStaffStep2,
  handleEditRemoveStaffStep2,
  handleEditStaffStep2,
  fetchListDepartment,
  fetchListStaffTimekeeping,
  fetchListStaffAll,
  fetchListWorkTime,
  fetchListTimekeeping,
  fetchDetailTimekeeping,
  resetStore,
  setDefaultSalonWorkTime,
  setParamSearch,
  fetchListStaffChecked,
  fetchDetailTimekeepingEdit,
  fetchAllSalon,
  resetStaffSelect,
  setSelectStaffAdd,
  resetStaffSelectStep1,
  resetTimeStaffStep1,
  updateChangeStaffFromStogare,
  updateTeamGroupStaffEdit
} = actions
export default reducer
