import { Button, Form, Select, Steps, Tabs } from 'antd'
import '../style/CheckinPage.scss'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { SELECT_GROUP_STEP_2 } from '../constant'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { useNavigate } from 'react-router-dom'
import Step2ListStaffAdd from '../components/Step2ListStaffAdd'
import Step2ListStaffEdit from '../components/Step2ListStaffEdit'
import { OptionType, StaffType } from '../type'
import { UpCircleFilled } from '@ant-design/icons'
import { resetStaffSelect, updateChangeStaffFromStogare } from '../reducers/checkin'

export const handleStaffActive = (all: StaffType[], edit: StaffType[]) => {
  const idAll = all.map((i) => i.id)
  const idEdit = edit.map((i) => i.id)
  const arrResult = idAll.filter((item) => !idEdit.includes(item))
  return arrResult.map((i) => all.find((staff) => staff.id === i))
}
function CheckinPage2() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { resource, step1 }: any = useAppSelector((state) => state.checkin)
  const checkinContainer = useRef<HTMLDivElement>()

  const staffAddActive = useMemo(
    () => handleStaffActive(!step1.type ? resource.listStaff : resource.listStaffAll, step1.staff),
    [step1.staff]
  )

  const [tabKey, setTabKey] = useState('0')
  const [selectGroup, setSelectGroup] = useState<OptionType | number>(SELECT_GROUP_STEP_2[0].value)
  const [selectedDepartment, setSelectedDepartment] = useState<number>()
  const [listMemberActive, setListMemberActive] = useState<StaffType[]>([])

  const [form] = Form.useForm()

  useEffect(() => {
    if (step1.salon === 0) navigate('/timekeeping/step-1')
    setListMemberActive(handleStaffActive(resource.listStaff, step1.staff))
  }, [step1.staff])

  useEffect(() => {
    if (selectedDepartment)
      setListMemberActive(staffAddActive.filter((staff) => staff?.departmentId === selectedDepartment))
    else setListMemberActive(staffAddActive)
  }, [selectedDepartment, step1.staff])
  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(resetStaffSelect())
  }, [])
  return (
    <div className='checkout_container' id='overflowHidden' ref={checkinContainer}>
      <Form form={form} name='dynamic_form_complex' style={{ paddingBottom: 100 }} autoComplete='off'>
        <div className='checkout_header'>
          <div className='checkout_header__title'>Tạo chấm công</div>
          <div className='steps_header'>
            <Steps
              current={1}
              style={{ fontSize: 14 }}
              items={[
                {
                  title: 'Tạo mới'
                },
                {
                  title: 'Chỉnh sửa'
                },
                {
                  title: 'Kết quả chấm công'
                }
              ]}
            />
          </div>
          <div className='step1_title'>BƯỚC 2: CHỈNH SỬA</div>
        </div>
        <div className='checkout_content'>
          <div className='step2'>
            <div className='text_label'>Ngày chấm công: {step1?.date}</div>
            <div className='text_label'>Salon: {step1?.salon?.displayName}</div>
            {step1?.type && <div className='text_label'>Salon cần chuyển đến: {step1?.salonSwitch?.displayName}</div>}

            <div style={{ display: 'flex', columnGap: '12px', marginTop: 10 }}>
              <Select
                className={`select_style_2 ${selectedDepartment && 'activeIconClear'}`}
                allowClear
                onChange={(value: number) => {
                  setSelectedDepartment(value)
                  if (tabKey === '0') {
                    dispatch(resetStaffSelect())
                    form.resetFields()
                    const store = localStorage.getItem('STAFF_EDIT')
                    if (store) dispatch(updateChangeStaffFromStogare())
                  }
                }}
                options={resource.department.map((i) => ({
                  value: i.id,
                  label: i.displayName
                }))}
                placeholder='Chọn tất cả bộ phận'
              />
              <Select
                className='select_style_2'
                defaultValue={SELECT_GROUP_STEP_2[0]}
                options={SELECT_GROUP_STEP_2}
                placeholder='Chọn tất cả bộ phận'
                onChange={(i) => {
                  dispatch(resetStaffSelect())
                  setSelectGroup(i)
                  form.resetFields()
                  const store = localStorage.getItem('STAFF_EDIT')
                  if (store) dispatch(updateChangeStaffFromStogare())
                }}
                disabled={tabKey === '1'}
              />
            </div>
            <Tabs
              defaultActiveKey='0'
              centered
              onChange={(key) => {
                setTabKey(key)
                form.resetFields()
              }}
              activeKey={tabKey}
              items={[
                {
                  name: !step1.type ? 'Danh sách đã chọn' : 'DS nhân sự cần chuyển đã chọn',
                  children: (
                    <Step2ListStaffEdit
                      selectGroup={selectGroup as number}
                      form={form}
                      tabKey={tabKey}
                      selectedDepartment={selectedDepartment}
                    />
                  )
                },
                {
                  name: !step1.type ? 'Thêm chấm công' : 'Thêm nhân sự cần chuyển',
                  children: (
                    <Step2ListStaffAdd
                      form={form}
                      checkinContainer={checkinContainer.current}
                      listMemberActive={listMemberActive}
                      tabKey={tabKey}
                      setTabKey={setTabKey}
                    />
                  )
                }
              ].map((item, index) => {
                return {
                  label: item.name,
                  key: String(index),
                  children: item.children
                }
              })}
            />
          </div>
        </div>
      </Form>
      <Button
        type='link'
        onClick={() => {
          checkinContainer.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        }}
        className='scrollToTop'
      >
        <UpCircleFilled style={{ fontSize: 25 }} />
      </Button>
    </div>
  )
}

export default React.memo(CheckinPage2)
