import React, { useEffect, useState } from 'react'
import { Form, Select, Space, Spin } from 'antd'
import { LIST_CHILD_GROUP, LIST_PARENT_GROUP } from '../constant'
import { TimeStaffType } from '../type'
import CheckableTag from 'antd/es/tag/CheckableTag'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { fetchDetailTimekeeping, handleEditStaffStep2 } from '../reducers/checkin'
import { getDetailTimeKeeping } from '../api'
import { useLocation } from 'react-router-dom'

type propsMember = {
  index: number
  isEdit?: boolean
}
const CheckedMemberEditComponent = ({ index, isEdit }: propsMember) => {
  const isCheckinDone = useLocation().pathname === '/timekeeping/checked'
  const editAgain = useLocation().pathname === '/timekeeping/edit'

  const dispatch = useAppDispatch()
  const { listSalon } = useAppSelector((state) => state.user.userInfo)
  const { type, salonSwitch, salon, date }: any = useAppSelector((state) => state.checkin.step1)
  const { listStaffChecked }: any = useAppSelector((state) => state.checkin.resource)

  const [parentValue, setParentValue] = useState(0)
  const [staffTime, setStaffTime] = useState(listStaffChecked[index]?.time)
  const [staffEdit, setStaffEdit] = useState({ ...listStaffChecked[index] })
  const [tagLoading, setTagLoading] = useState(false)

  const handleChangeStaff = (tag: TimeStaffType, checked: boolean) => {
    const newStaffTime = staffTime.map((i: TimeStaffType) => {
      if (i.hourId === tag.hourId)
        return {
          ...tag,
          isActive: checked
        }
      return i
    })
    setStaffTime(newStaffTime)
    setStaffEdit({ ...staffEdit, time: newStaffTime })
  }

  const handleFetchDetailTimekeeping = async () => {
    if (salon?.id && listStaffChecked[index]?.id && date) {
      setTagLoading(true)
      const { data } = await getDetailTimeKeeping({
        salonId: listStaffChecked[index]?.salonId,
        staffId: listStaffChecked[index]?.id,
        workDate: date
      })
      setTagLoading(false)
      if (data.statusCode === 0) {
        const time = data.data.hours.map((i) => ({
          ...i,
          hourName: i.hour,
          isActive: i.iskeeping
        }))
        dispatch(fetchDetailTimekeeping({ index, time: { ...listStaffChecked[index], time } }))
      }
    }
  }
  useEffect(() => {
    setStaffTime(listStaffChecked[index]?.time)
  }, [listStaffChecked[index]])

  useEffect(() => {
    if (listSalon.length > 0) {
      setParentValue(listStaffChecked[index]?.select_parent_group)
    }

    if (!isEdit || editAgain || isCheckinDone) {
      handleFetchDetailTimekeeping()
    }
  }, [])
  useEffect(() => {
    if (staffEdit?.time) {
      dispatch(handleEditStaffStep2(staffEdit))
    }
  }, [staffEdit])
  const renderTagStatus = (tag: TimeStaffType) => {
    if (tag.isBookStylist && tag.isMakeBill) return 'KB'
    if (tag.isBookStylist) return 'K'
    if (tag.isMakeBill) return 'B'
    return
  }
  const renderTagBackground = (tag: TimeStaffType) => {
    if (salon.salonName !== tag.salonName && tag?.salonName && tag?.iskeeping) return 'tag_support'
    if (tag?.isOverTime && tag.isActive) return 'tag_overTime'
    return ''
  }

  return (
    <div className='staff_edit_tags'>
      {type && <div className='text_label'>Chuyển đến salon: {salonSwitch?.salonName}</div>}

      <div className='text_label'>Đã chấm công: {listStaffChecked[index]?.selectedCheckin?.name}</div>
      <div>
        <div className='wrap_form_item' style={{ display: 'block' }}>
          <div className='wrap_select_staff'>
            <div className='select_parent_group'>
              Đội lớn
              <Form.Item style={{ marginBottom: 0 }} name={[index, 'select_parent_group']}>
                <Select
                  disabled={!isEdit || (editAgain && listStaffChecked[index].status === 'PENDING')}
                  className='select_team_goup'
                  onChange={(value) => {
                    setParentValue(value)
                    setStaffEdit({ ...staffEdit, select_parent_group: value })
                  }}
                  options={LIST_PARENT_GROUP.map((item) => ({
                    label: item,
                    value: item
                  }))}
                  defaultValue={listStaffChecked[index]?.select_parent_group}
                />
              </Form.Item>
            </div>
            <div className='select_child_group'>
              Đội nhỏ
              <Form.Item style={{ marginBottom: 0 }} name={[index, 'select_child_group']}>
                <Select
                  disabled={!isEdit || (editAgain && listStaffChecked[index].status === 'PENDING')}
                  onChange={(e) => setStaffEdit({ ...staffEdit, select_child_group: e })}
                  className='select_team_goup'
                  options={(LIST_CHILD_GROUP[parentValue] || []).map((item) => ({
                    label: item,
                    value: item
                  }))}
                  defaultValue={listStaffChecked[index]?.select_child_group}
                />
              </Form.Item>
            </div>
          </div>
          <div>
            <Spin spinning={tagLoading}>
              <Space className='switchEmployee_Space'>
                {(staffTime || []).map((tag: TimeStaffType) => (
                  <CheckableTag
                    className={`tag ${renderTagBackground(tag)}`}
                    key={tag.hourId}
                    checked={tag.isActive}
                    onChange={(checked) => {
                      if (!isEdit || (editAgain && listStaffChecked[index].status === 'PENDING')) return
                      handleChangeStaff(tag, checked)
                    }}
                  >
                    <div className='item_tag'>
                      <div className='tag_status'>{renderTagStatus(tag)}</div>
                      {salon.salonName !== tag.salonName && tag?.iskeeping && (
                        <span className='tag_salon_support'>{tag.salonName}</span>
                      )}
                      <span className='item_tag_top'>{tag.hourName}</span>
                    </div>
                  </CheckableTag>
                ))}
              </Space>
            </Spin>
          </div>
          <div className='workingTime_description'>B: Đã in bill, K: Khách book, KB: Khách book đã in bill</div>
        </div>
      </div>
    </div>
  )
}
export default React.memo(CheckedMemberEditComponent)
