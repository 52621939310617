import React, { useState, useEffect, useRef } from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import MemberComponent from './MemberComponent'
import { StaffType } from '../type'
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
export type Staff = {
  id: number
  fullName: string
  salonId: number
  departmentId: number
  departmentName: string
  salonIdWorking: number
  noteStatusSalonCurrent: any
}
type propsListMember = {
  listMemberActive: Staff[]
  checkoutContainer: HTMLDivElement
  form: any
}
const ListMember = ({ listMemberActive, checkoutContainer, form }: propsListMember) => {
  const page = useRef(1)
  const [listMemberRender, setListMemberRender] = useState<Staff[]>([])

  function handleScroll() {
    if (checkoutContainer) {
      const { scrollTop, scrollHeight, clientHeight } = checkoutContainer
      if (scrollTop + clientHeight > scrollHeight * 0.9) {
        page.current += 1
        setListMemberRender(listMemberActive.slice(0, (page.current + 1) * 10))
      }
    }
  }

  useEffect(() => {
    setListMemberRender(listMemberActive.slice(0, 10))
    page.current = 1
  }, [listMemberActive])

  useEffect(() => {
    checkoutContainer?.addEventListener('scroll', handleScroll)
    return () => {
      checkoutContainer?.removeEventListener('scroll', handleScroll)
    }
  }, [checkoutContainer, listMemberActive])

  return (
    <>
      {listMemberRender.map((member: StaffType) => {
        return <MemberComponent key={member.id} member={member} index={member.id} form={form} />
      })}
      <div className='spinner'>
        {listMemberRender.length < listMemberActive.length && (
          <Spin style={{ display: 'block', margin: 'auto' }} indicator={antIcon} />
        )}
      </div>
    </>
  )
}
export default React.memo(ListMember)
