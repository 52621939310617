import React, { useEffect, useState, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Menu } from 'antd'
// import { CloseOutlined, MenuOutlined } from '@ant-design/icons'
import { MENUS } from 'utils/mocks'
import { useDispatch } from 'react-redux'
import { innitState, resetStore, setParamSearch } from 'pages/checkin/reducers/checkin'

const { SubMenu } = Menu
type propsMenu = {
  setCollapsed: (collapsed: boolean) => void
  collapsed: boolean
}
export default function MainMenu({ setCollapsed, collapsed }: propsMenu) {
  const dispatch = useDispatch()
  const location = useLocation()
  const [selectedKeys, setSelectedKeys] = useState([location.pathname])
  const click = useRef(false)
  useEffect(() => {
    setSelectedKeys([location.pathname])
    if (['/timekeeping/checked', '/timekeeping/step-1'].includes(location.pathname) && click.current) {
      dispatch(setParamSearch(innitState.paramSearch))
      dispatch(resetStore())
      click.current = false
    }
  }, [location.pathname])

  return (
    <Menu
      theme='dark'
      mode='inline'
      defaultOpenKeys={['timekeeping']}
      selectedKeys={selectedKeys}
      defaultSelectedKeys={['/timekeeping/step-1`']}
    >
      {MENUS.map((item) => {
        if (item.nested.length) {
          return (
            <SubMenu key={item.key} icon={item.icon} title={item.title}>
              {item.nested.map((nestedItem) => {
                return (
                  <Menu.Item key={nestedItem.key} icon={nestedItem?.icon}>
                    <Link
                      onClick={() => {
                        click.current = true
                      }}
                      to={nestedItem.path}
                    >
                      {nestedItem.title}
                    </Link>
                  </Menu.Item>
                )
              })}
            </SubMenu>
          )
        }
        return (
          <Menu.Item key={item.key} icon={item.icon}>
            <Link to={item.path}>{item.title}</Link>
          </Menu.Item>
        )
      })}
    </Menu>
  )
}
