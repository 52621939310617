import { Button, Form, Input, Select, Steps, Tabs } from 'antd'
import '../style/CheckinPage.scss'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { SELECT_GROUP_STEP_2 } from '../constant'
import store, { useAppDispatch, useAppSelector } from 'redux/store'
import { useNavigate } from 'react-router-dom'
import Step2ListStaffAdd from '../components/Step2ListStaffAdd'
import Step2ListStaffEdit from '../components/Step2ListStaffEdit'
import { OptionType, StaffType } from '../type'
import { handleFetchHourTimekeeping, handleFetchTimekeeping } from '../hook/fetchData'
import { UpCircleFilled } from '@ant-design/icons'
import { AppContext } from 'layouts/DashboardLayout'
import { debounce } from 'lodash'
import { removeVietnameseDiacritics } from 'utils/helpers/util'
import {
  resetStaffSelect,
  resetTimeStaffStep1,
  setParamSearch,
  updateChangeStaffFromStogare
} from '../reducers/checkin'

const handleStaffActive = (all: StaffType[], edit: StaffType[]) => {
  const idAll = all.map((i) => i.id)
  const idEdit = edit.map((i) => i.id)
  const arrResult = idAll.filter((item) => !idEdit.includes(item))
  return arrResult.map((i) => all.find((staff) => staff.id === i))
}
function EditChecked() {
  const { notification } = useContext(AppContext)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { resource, paramSearch }: any = useAppSelector((state) => state.checkin)
  const { type, salon, workTime, date }: any = useAppSelector((state) => state.checkin.step1)

  const checkinContainer = useRef<HTMLDivElement>()
  const [tabKey, setTabKey] = useState('0')
  const [searchName, setSearchName] = useState('')
  const [selectGroup, setSelectGroup] = useState<any>(paramSearch.workTime)
  const [selectedDepartment, setSelectedDepartment] = useState<number>(paramSearch.department)
  const [listMemberActive, setListMemberActive] = useState<StaffType[]>([])
  const [listMemberFilter, setListMemberFilter] = useState<any>([])

  const [form] = Form.useForm()
  const staffAddActive = useMemo(() => {
    const { staff } = store.getState().checkin.step1
    return handleStaffActive(!type ? resource.listStaff : resource.listStaffAll, staff)
  }, [resource.listStaffAll, tabKey])

  const handleChange = debounce((event) => {
    setSearchName(event.target.value.toLowerCase())
    if (tabKey === '0') {
      dispatch(resetStaffSelect())
      const store = localStorage.getItem('STAFF_EDIT')
      if (store) dispatch(updateChangeStaffFromStogare())
      form.resetFields()
    }
  }, 500)
  useEffect(() => {
    if (salon === 0) navigate('/timekeeping/checked')
    setListMemberActive(staffAddActive)
  }, [staffAddActive])

  useEffect(() => {
    handleFetchHourTimekeeping({
      salonId: salon?.id,
      workTimeId: workTime && workTime[0]?.id
    })
  }, [tabKey])
  useEffect(() => {
    if (salon?.id && date) {
      handleFetchTimekeeping({ salonId: salon.id, workDate: date }, dispatch, notification)
    }
  }, [tabKey])
  useEffect(() => {
    if (selectedDepartment)
      setListMemberActive(staffAddActive.filter((staff) => staff?.departmentId === selectedDepartment))
    else setListMemberActive(staffAddActive)
  }, [selectedDepartment, resource.listStaffAll, tabKey])
  useEffect(() => {
    dispatch(resetTimeStaffStep1())
    dispatch(resetStaffSelect())
  }, [])
  useEffect(() => {
    setListMemberFilter(
      searchName.toLowerCase()
        ? listMemberActive.filter(
            (staff) =>
              removeVietnameseDiacritics((staff?.fullName || '').toLowerCase()).includes(
                removeVietnameseDiacritics(searchName).toLowerCase()
              ) || staff.id.toString().includes(removeVietnameseDiacritics(searchName).toLowerCase())
          ) || []
        : listMemberActive
    )
  }, [searchName, listMemberActive])

  return (
    <div
      className={`checkout_container ${paramSearch.isSupport === 2 && 'disable_tab'}`}
      id='overflowHidden'
      ref={checkinContainer}
    >
      <Form form={form} name='dynamic_form_complex' style={{ paddingBottom: 100 }} autoComplete='off'>
        <div className='checkout_header'>
          <div className='checkout_header__title'>Sửa chấm công</div>
          <div className='steps_header'>
            <Steps
              current={1}
              style={{ fontSize: 14 }}
              items={[
                {
                  title: 'Chấm công'
                },
                {
                  title: 'Chỉnh sửa'
                },
                {
                  title: 'Kết quả chấm công'
                }
              ]}
            />
          </div>
          <div className='step1_title'>BƯỚC 2: XEM VÀ CHỈNH SỬA</div>
        </div>
        <div className='checkout_content'>
          <div className='step2'>
            <div className='text_label'>Ngày chấm công: {date}</div>
            <div className='text_label'>Salon: {salon?.salonName}</div>
            <div className='wrap_top' style={{ display: 'flex', columnGap: '10px' }}>
              <Select
                className={`select_style_2 ${selectedDepartment && 'activeIconClear'}`}
                allowClear
                onChange={(value: number) => {
                  setSelectedDepartment(value)
                  dispatch(setParamSearch({ ...paramSearch, department: value }))
                  if (tabKey === '0') {
                    dispatch(resetStaffSelect())
                    const store = localStorage.getItem('STAFF_EDIT')
                    if (store) dispatch(updateChangeStaffFromStogare())
                    form.resetFields()
                  }
                }}
                options={resource.department.map((i) => ({
                  value: i.id,
                  label: i.displayName
                }))}
                defaultValue={paramSearch.department}
                placeholder='Chọn tất cả bộ phận'
              />
              <Select
                className='select_style_2'
                defaultValue={paramSearch.workTime}
                options={SELECT_GROUP_STEP_2}
                onChange={(i) => {
                  dispatch(resetStaffSelect())
                  dispatch(setParamSearch({ ...paramSearch, workTime: i }))
                  const store = localStorage.getItem('STAFF_EDIT')
                  if (store) dispatch(updateChangeStaffFromStogare())
                  setSelectGroup(i)
                  form.resetFields()
                }}
                disabled={tabKey === '1'}
              />
            </div>
            <Input
              allowClear
              onChange={handleChange}
              className='searchByNameId'
              placeholder='Tìm kiếm theo họ và tên/ID'
            />
            <Tabs
              defaultActiveKey='0'
              centered
              onChange={(key) => {
                setTabKey(key)
              }}
              activeKey={tabKey}
              items={[
                {
                  name: !type ? 'Danh sách đã chọn' : 'DS nhân sự cần chuyển đã chọn',
                  children: (
                    <Step2ListStaffEdit
                      checkinContainer={checkinContainer.current}
                      selectGroup={selectGroup?.label ? selectGroup?.value : selectGroup}
                      form={form}
                      tabKey={tabKey}
                      selectedDepartment={selectedDepartment}
                      isEdit={true}
                      searchName={removeVietnameseDiacritics(searchName).toLowerCase()}
                    />
                  )
                },
                paramSearch.isSupport !== 2
                  ? {
                      name: !type ? 'Thêm chấm công' : 'Thêm nhân sự cần chuyển',
                      children: (
                        <Step2ListStaffAdd
                          form={form}
                          checkinContainer={checkinContainer.current}
                          listMemberActive={listMemberFilter}
                          tabKey={tabKey}
                          setTabKey={setTabKey}
                        />
                      )
                    }
                  : undefined
              ]
                .filter((i) => i)
                .map((item, index) => {
                  return {
                    label: item.name,
                    key: String(index),
                    children: item.children
                  }
                })}
            />
          </div>
        </div>
      </Form>
      <Button
        type='link'
        onClick={() => {
          checkinContainer.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        }}
        className='scrollToTop'
      >
        <UpCircleFilled style={{ fontSize: 25 }} />
      </Button>
    </div>
  )
}

export default React.memo(EditChecked)
