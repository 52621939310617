/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/rules-of-hooks */
import { Form, Checkbox, Collapse, Modal, Table } from 'antd'
import { useState, useRef, useEffect, useMemo } from 'react'
import MemberEditComponent from './MemberEditComponent'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { setListStaffEditStep2 } from '../reducers/checkin'
import ButtonStatus, { STATUS_COLOR } from 'components/ButtonStatus'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getLogTimeKeeping } from '../api'
import { SELECT_STATUS_STEP_3 } from '../constant'
import dayjs from 'dayjs'
import clock from 'images/clock.png'

const { Panel } = Collapse
type ListPanelStaffFilterProps = {
  form?: any
  listStaff?: any
  team?: any
  selectGroup?: number
  isEdit?: boolean
  checkinContainer?: any
}
const ListPanelStaffFilter = ({ form, listStaff, team, selectGroup, isEdit = true }: ListPanelStaffFilterProps) => {
  const isMobile = useMemo(() => window.innerWidth <= 500, [])

  const editAgain = useLocation().pathname.includes('/timekeeping/edit')
  const listStaffStore = useAppSelector((state) => state.checkin.step1.staff)
  const { department } = useAppSelector((state: any) => state.checkin.resource)

  const { role } = useAppSelector((state) => state.user)
  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const [selectCheckbox, setSelectCheckbox] = useState<any>((listStaff || []).map(() => false))
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState<any>({
    title: '',
    content: '',
    status: ''
  })

  const ref = useRef<HTMLDivElement>()
  const scrollRef = useRef(null)

  const columns = [
    {
      title: 'Thời gian',
      dataIndex: 'logDate',
      key: 'logDate',
      render: (logDate) => <div style={{ width: 85 }}>{dayjs(logDate).format('YYYY-MM-DD HH:mm:ss')}</div>
    },
    {
      title: 'Người thực hiện',
      dataIndex: 'userAction',
      key: 'userAction',
      render: (userAction) => <div style={{ width: 55 }}>{userAction}</div>
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description'
    }
  ]
  const handleHistoryTimekeeing = async (streamId: string, status: string) => {
    const data = await getLogTimeKeeping(streamId)

    if (data.data.statusCode === 200) {
      setIsModalOpen(true)

      setModalContent({
        title: SELECT_STATUS_STEP_3.find((i) => i.value === status).label,
        content: data.data.data,
        status
      })
    }
  }
  useEffect(() => {
    const values = form.getFieldsValue()
    let check = true

    selectCheckbox.forEach((i) => {
      if (!i) check = false
    })

    form.setFieldsValue({
      [selectGroup === 0 ? team?.id : team]: {
        checkbox_group_edit_all: check
      }
    })

    const id_staff_select = Object.keys(values).filter((i) => {
      return values[i]?.checkbox_group_edit
    })
    dispatch(setListStaffEditStep2(id_staff_select))
  }, [selectCheckbox])

  useEffect(() => {
    if (scrollRef.current) {
      const timer1 = setTimeout(() => {
        if (scrollRef.current)
          scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
      }, 1000)
      return () => {
        clearTimeout(timer1)
      }
    }
  }, [])

  return (
    <>
      <div className='header_list_staff_edit' style={{ marginLeft: '-25px' }}>
        <Form.Item
          valuePropName='checked'
          name={[selectGroup === 0 ? team?.id : team, 'checkbox_group_edit_all']}
          style={{ margin: '0 22px 0 0', display: isEdit ? '' : 'none' }}
        >
          <Checkbox
            onChange={(e) => {
              setSelectCheckbox(
                listStaff.map((staff) => {
                  if (staff.status !== 'PENDING') {
                    form.setFieldsValue({
                      [staff.id]: { checkbox_group_edit: e.target.checked }
                    })
                  }
                  return e.target.checked
                })
              )
            }}
          />
        </Form.Item>
        {selectGroup === 1
          ? team === 0
            ? `Danh sách đội khác`
            : `Danh sách đội ${team}`
          : `Danh sách ca ${team?.name} (${team?.startTime} - ${team?.endTime})`}
      </div>
      <Collapse defaultActiveKey={useParams()?.id} ref={ref} ghost>
        {listStaff
          .sort((a, b) => a.id - b.id)
          .sort((a, b) => a.departmentId - b.departmentId)
          .map((staff, index) => {
            return (
              <Panel
                ref={Number(useParams()?.id) === staff.id ? scrollRef : null}
                key={`${staff.id}`}
                forceRender={false}
                header={
                  <>
                    <div
                      onChange={(e) => e.stopPropagation()}
                      className='wrap_form_item'
                      style={{ position: 'relative' }}
                    >
                      <div style={{ display: 'flex' }}>
                        <Form.Item
                          name={[staff.id, 'checkbox_group_edit']}
                          valuePropName={'checked'}
                          style={{ display: isEdit ? '' : 'none', marginRight: 22 }}
                          // @ts-ignore: Unreachable code error
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Checkbox
                            style={{
                              opacity: editAgain && staff.status === 'PENDING' ? '0' : '1',
                              pointerEvents: editAgain && staff.status === 'PENDING' ? 'none' : 'auto'
                            }}
                            onChange={() => {
                              const data = form.getFieldsValue()
                              setSelectCheckbox(
                                listStaff.map((i) => {
                                  return data[i.id].checkbox_group_edit ? true : false
                                })
                              )
                            }}
                            checked={selectCheckbox[index]}
                            disabled={editAgain && staff.status === 'PENDING'}
                          />
                        </Form.Item>
                        <div className='item_panel_member'>
                          <div className='fullName'>{staff.fullName}</div>
                          <div className='departmentName'>{`${
                            department.find((i) => i.id === staff?.departmentId)?.displayName || 'không xác định'
                          } - ${staff.id}`}</div>
                        </div>
                      </div>

                      {!isEdit && staff?.status && (
                        <>
                          <ButtonStatus
                            onClick={(e) => {
                              e.stopPropagation()
                              handleHistoryTimekeeing(staff.streamId, staff.status)
                            }}
                            status={staff.status}
                          />
                        </>
                      )}
                      <div style={{ display: 'flex', columnGap: 8 }}>
                        <div className='item_expend'>
                          {staff?.numberOfWorkHourOver > 0 && (
                            <div className='overtime'>
                              {staff?.numberOfWorkHourOver} <img src={clock} alt='button' />
                            </div>
                          )}
                          {staff.select_child_group > 0 && !selectGroup ? (
                            <div
                              className='group_team'
                              style={{ marginRight: editAgain ? 0 : 30 }}
                            >{`Đ${staff.select_child_group}`}</div>
                          ) : (
                            ''
                          )}
                          {selectGroup === 1 ? (
                            <div style={{ fontSize: 12, marginRight: editAgain ? 0 : 30 }}>
                              <div style={{ textAlign: 'center', fontWeight: 500 }}>
                                {editAgain
                                  ? staff?.workTimeName || staff?.selectedCheckin?.name
                                  : staff?.selectedCheckin?.name}
                              </div>
                              <div
                                style={{ fontSize: 10 }}
                              >{`${staff?.selectedCheckin.startTime} - ${staff?.selectedCheckin.endTime}`}</div>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                        {editAgain && staff?.status && (
                          <ButtonStatus
                            onClick={(e) => {
                              e.stopPropagation()
                              handleHistoryTimekeeing(staff.streamId, staff.status)
                            }}
                            status={staff.status}
                          />
                        )}
                      </div>
                    </div>
                    {role === 'ADMIN' && useLocation().pathname.includes('/timekeeping/edit') && (
                      <div className='text_updateTime'>
                        Cập nhật giờ chấm công của tháng <Checkbox />
                      </div>
                    )}
                  </>
                }
              >
                <MemberEditComponent form={form} index={listStaffStore.findIndex((i) => i.id === staff.id)} />
              </Panel>
            )
          })}
      </Collapse>
      {isModalOpen && (
        <Modal
          centered
          title={
            <div
              style={{
                fontSize: 18,
                textAlign: 'center',
                color: STATUS_COLOR[modalContent.status]
              }}
            >
              {modalContent.title}
            </div>
          }
          open={isModalOpen}
          onOk={() => setIsModalOpen(false)}
          onCancel={() => setIsModalOpen(false)}
          footer={[]}
          style={{ width: 'auto' }}
        >
          <div className='wrap_modal'>
            {
              <Table
                style={{ fontSize: 12 }}
                pagination={{ defaultPageSize: isMobile ? 5 : 10, showSizeChanger: false }}
                dataSource={modalContent?.content}
                columns={columns}
              />
            }
          </div>
        </Modal>
      )}
    </>
  )
}

export default React.memo(ListPanelStaffFilter)
