import dayjs from 'dayjs'
import { StaffType, TimeStaffType } from './type'

export const handleStaffActive = (all: StaffType[], edit: StaffType[]) => {
  const idAll = all.map((i) => i.id)
  const idEdit = edit.map((i) => i.id)
  const arrResult = idAll.filter((item) => !idEdit.includes(item))
  return arrResult.map((i) => all.find((staff) => staff.id === i))
}
export const convertListTimeToString = (list: TimeStaffType[], salon?: string) => {
  if (!list) return undefined
  const arr = list
    .filter((i) => {
      if (i.salonName) return i.isActive && salon && i.salonName === salon
      else return i.isActive
    })
    .map((i) => i.hourId)
  return arr.join(',')
}
export const isPastDate = (dateString) => {
  const currentDate = dayjs().add(-1, 'day')
  const inputDate = dayjs(dateString)
  return inputDate.isBefore(currentDate)
}

export const validateEditPermission = (role: string, dateSelected: string) => {
  const currentDate = dayjs()
  const nineAM = currentDate.set('hour', 9).set('minute', 0).set('second', 0)
  const dateToCompare = dayjs(dateSelected)
  const yesterday = dayjs().subtract(1, 'day')

  // cho phep SM sua tat ca cac ngay
  if (role === 'SM') return true

  const dayDifference = dateToCompare.diff(currentDate, 'day')
  if (dayDifference < 31 && dayDifference >= 0) {
    // cho chấm công 31 ngày tương lai
    return true
  } else if (currentDate.isBefore(nineAM) && yesterday.isSame(dateToCompare, 'day')) {
    // trước 9am SM được phép sửa với ngày chọn là ngày hôm trước
    return true
  } else if (role === 'ADMIN') {
    // Lấy ngày tháng trước
    const lastMonth = currentDate.subtract(1, 'month')

    // chỉ được sửa ngày quá khứ nhưng trước ngày 11
    if (dateToCompare.isSame(lastMonth, 'month') && currentDate.date() < 11) {
      return true
    }
    // được sửa tất cả các ngày của tháng hiện tại
    if (currentDate.isSame(dateToCompare, 'month')) {
      return true
    }
  }

  return false
}
