import React, { useCallback, createContext, useEffect, useState, useRef, useMemo } from 'react'
import { Layout, Dropdown, Button, Menu, Spin, notification } from 'antd'
import { CaretDownOutlined, LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import './styles.scss'
import MainRouter from '../routes/MainRouter'
import logo from 'images/logo_30shine.png'
import personIcon from 'images/person.png'
import { SSO } from '@30shine/sso-erp'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { fetchUser } from 'redux/slices/userSlice'
import MainMenu from './MainMenu'
import { BrowserRouter } from 'react-router-dom'
import { orNull } from 'utils/selector'
type NotificationType = 'success' | 'info' | 'warning' | 'error'

export const AppContext = createContext({
  notification: undefined,
  loading: false,
  setLoading: undefined
})
const sso = new SSO(process.env.REACT_APP_ENV || 'test')
const { Header, Sider, Content } = Layout

function DashboardLayout() {
  const [api, contextHolder] = notification.useNotification()
  const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
    api[type]({ message, description })
  }
  const [loading, setLoading] = useState(false)
  const isMobile = useMemo(() => window.innerWidth <= 650, [window.innerWidth])
  const sideBarRef = useRef<any>()
  const headerRef = useRef<any>()
  const { userInfo } = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()
  const user = JSON.parse(localStorage.getItem('user'))
  const fullName = user?.fullName || ''
  const logOut = async () => {
    localStorage.clear()
    sso.ssoLogout()
  }

  const menu = (
    <Menu>
      {isMobile && <strong style={{ marginLeft: 10 }}> {`Xin chào, ${fullName}`}</strong>}
      <Menu.Item style={{ width: 150 }} key='1' icon={<LogoutOutlined />} onClick={logOut}>
        Đăng xuất
      </Menu.Item>
    </Menu>
  )

  const ComponentLayout = () => {
    const [collapsed, setCollapsed] = useState(false)
    const [show, setShow] = useState(false)

    const MainRouterComponent = useCallback(() => {
      return <MainRouter />
    }, [userInfo])

    const handleOutsideClick = (event) => {
      if (
        sideBarRef.current &&
        !sideBarRef.current.contains(event.target) &&
        !headerRef.current.contains(event.target)
      ) {
        if (show) setShow(!show)
      }
    }
    useEffect(() => {
      if (show) {
        document.addEventListener('click', handleOutsideClick)
      } else {
        document.removeEventListener('click', handleOutsideClick)
      }
      return () => {
        document.removeEventListener('click', handleOutsideClick)
      }
    }, [show])

    return (
      <AppContext.Provider
        value={{
          notification: openNotificationWithIcon,
          loading,
          setLoading
        }}
      >
        <Layout id='layout'>
          {contextHolder}

          <Header className='layout__header' ref={headerRef}>
            {isMobile && (
              <div className='isMobile'>
                {show ? (
                  <MenuFoldOutlined
                    onClick={(e) => {
                      e.stopPropagation()
                      setShow(!show)
                    }}
                    style={{ color: '#fff', fontSize: 30, marginRight: 20 }}
                  />
                ) : (
                  <MenuUnfoldOutlined
                    onClick={(e) => {
                      e.stopPropagation()
                      setShow(!show)
                    }}
                    style={{ color: '#fff', fontSize: 30, marginRight: 20 }}
                  />
                )}
              </div>
            )}
            <a href={'https://erp.30shine.com'} className='header__logo isNotMobile' target={'_blank'} rel='noreferrer'>
              <b style={{ fontSize: '20px', textTransform: 'uppercase', color: '#fff', cursor: 'pointer' }}>
                Chấm công
              </b>
            </a>
            <Dropdown overlay={menu}>
              <Button className='header__account-button'>
                <img src={personIcon} alt='' />
                <strong className='accountName' style={{ marginLeft: 10 }}>{`${!isMobile ? 'Xin chào,' : ''} ${orNull(
                  'fullName',
                  userInfo
                )}`}</strong>
                <CaretDownOutlined color='white' />
              </Button>
            </Dropdown>
          </Header>
          <Layout className='layout__body'>
            <div
              style={{ transition: 'left .2s ease-in' }}
              className={`responsive_dashboard ${!show ? 'activeSideBar' : 'inactiveSideBar'}`}
            >
              <Sider
                ref={sideBarRef}
                className={`${!collapsed && 'body__sidebar'} `}
                trigger={null}
                collapsible
                collapsed={collapsed}
                defaultCollapsed
              >
                <div className='isMobile'>
                  <a
                    href={'https://erp.30shine.com'}
                    className='header__logo logo_mobile'
                    target={'_blank'}
                    rel='noreferrer'
                  >
                    <img width={90} src={logo} alt='' />
                  </a>
                </div>
                <MainMenu setCollapsed={setCollapsed} collapsed={collapsed} />
              </Sider>
            </div>
            {/* )} */}

            <Content className='body__page'>
              <Spin spinning={loading}>
                <MainRouterComponent />
              </Spin>
            </Content>
          </Layout>
        </Layout>
      </AppContext.Provider>
    )
  }

  useEffect(() => {
    if (!userInfo.fullName) {
      dispatch(fetchUser())
    }
  })

  return (
    <BrowserRouter>
      <ComponentLayout />
    </BrowserRouter>
  )
}

export default DashboardLayout
