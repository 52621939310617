import React, { Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
import Page404 from '../pages/errors/404'
import Page403 from '../pages/errors/403'
import Page500 from '../pages/errors/500'
import ScrollToTop from 'components/SrollToTop'
import Loading from '../components/Loading'
import { useAppSelector } from 'redux/store'

import CheckinPage1 from 'pages/checkin/pages/CheckinPage1'
import CheckinPage2 from 'pages/checkin/pages/CheckinPage2'
import ViewChecked from 'pages/checkin/pages/ViewChecked'
import EditChecked from 'pages/checkin/pages/EditChecked'

const AuthorizationPage = (element, url: string) => {
  const urlAccess = useAppSelector((state) => state.user.urlAccess)
  if (urlAccess) {
    return urlAccess.find((i) => i.path === url) ? element : <Page403 />
  }
}
function MainRouter() {
  const routes = useRoutes([
    {
      path: '/timekeeping/step-1',
      element: AuthorizationPage(<CheckinPage1 />, '/timekeeping/step-1')
    },
    {
      path: '/timekeeping/step-2',
      element: AuthorizationPage(<CheckinPage2 />, '/timekeeping/step-1')
    },

    {
      path: '/timekeeping/edit',
      element: AuthorizationPage(<EditChecked />, '/timekeeping/step-1')
    },
    {
      path: '/timekeeping/edit/:id',
      element: AuthorizationPage(<EditChecked />, '/timekeeping/step-1')
    },
    {
      path: '/timekeeping/checked',
      element: AuthorizationPage(<ViewChecked />, '/timekeeping/checked')
    },
    {
      path: '/403',
      element: <Page403 />
    },
    {
      path: '/404',
      element: <Page404 />
    },
    {
      path: '/500',
      element: <Page500 />
    }
  ])
  return (
    <>
      <ScrollToTop />
      <Suspense fallback={<Loading />}>{routes}</Suspense>
    </>
  )
}

export default MainRouter
