import { Button, Spin } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import store, { useAppDispatch, useAppSelector } from 'redux/store'
import {
  handleAddStaffStep2,
  handleEditRemoveStaffStep2,
  resetStaffSelect,
  resetStore,
  setParamSearch,
  setStep1,
  updateChangeStaffFromStogare
} from '../reducers/checkin'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  createTimeKeeping,
  deleteTimeKeeping,
  getActionTimekeeping,
  getListHourTimekeeping,
  updateTimeKeeping
} from '../api'
import { PayloadCreateTimekeeping, PayloadDeleteTimekeeping } from '../type'
import { convertListTimeToString, isPastDate, validateEditPermission } from '../util'
import Modal from 'antd/es/modal/Modal'
import { AppContext } from 'layouts/DashboardLayout'
import { LoadingOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { ACTION_TIMEKEEPING } from '../constant'

export const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#1890ff' }} spin />

type PropsFooterCheckin = {
  tabKey?: string
  type: string
  setTabKey?: any
  form: any
}
const FooterCheckin = ({ tabKey, type, setTabKey, form }: PropsFooterCheckin) => {
  const { notification } = useContext(AppContext)

  const editAgain = useLocation().pathname.includes('/timekeeping/edit')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const [loadingButton, setLoadingButton] = useState(false)

  const {
    paramSearch,
    step1,
    step2: { listStaffEdit, listStaffAdd }
  }: any = useAppSelector((state) => state.checkin)
  const { role } = useAppSelector((state) => state.user)
  const hasEditPermission = validateEditPermission(role, step1.date)

  const handleCreateTimekeeping = async (payload) => {
    try {
      setLoadingButton(true)
      const res = await createTimeKeeping(payload)

      notification(
        res.data.statusCode === 0 ? 'success' : 'error',
        res.data.statusCode === 0 ? 'Thành công' : 'Thất bại',
        res.data.message
      )
      if (res.data.statusCode === 0) {
        dispatch(setStep1({ ...step1, type: false }))
        dispatch(
          setParamSearch({
            ...paramSearch,
            salon: step1.salon.id,
            date: step1.date,
            isSupport: !step1.type ? 0 : 1
          })
        )
        navigate(
          step1?.salonSwitch?.id && step1?.salonSwitch?.id !== step1.salon.id
            ? '/timekeeping/checked?sync=true&switch=true'
            : '/timekeeping/checked?sync=true'
        )
        localStorage.setItem('STAFF_EDIT', '')
      }
    } catch (error) {
      console.log(error)
      notification('error', 'Lỗi', 'Có lỗi xảy ra')
    }
    setLoadingButton(false)
  }
  const handleUpdateTimekeeping = async (payload) => {
    try {
      setLoadingButton(true)
      const res = await updateTimeKeeping(payload)
      notification(
        res.data.statusCode === 0 ? 'success' : 'error',
        res.data.statusCode === 0 ? 'Thành công' : 'Thất bại',
        res.data.message
      )
      if (res.data.statusCode === 0) {
        navigate('/timekeeping/checked?sync=true')
        localStorage.setItem('STAFF_EDIT', '')
      }
    } catch (error) {
      console.log(error)
      notification('error', 'Lỗi', 'Có lỗi xảy ra')
    }

    setLoadingButton(false)
  }
  const submitDataToEnd = async () => {
    let flag = false
    const store_local = localStorage.getItem('STAFF_EDIT') ? JSON.parse(localStorage.getItem('STAFF_EDIT')) : ''
    if (step1.staff.length === 0) {
      notification('error', 'Thất bại', 'Không có nhân sự chấm công')
      return
    }
    const staff_step1_current = store.getState().checkin.step1.staff
    const resultEnrollData =
      store_local && store_local?.length > 0
        ? step1.staff.map((i) => (store_local.find((j) => j.id === i.id) ? store_local.find((j) => j.id === i.id) : i))
        : staff_step1_current
    const payload: PayloadCreateTimekeeping = {
      doUserId: 0,
      salonId: step1.type ? step1.salonSwitch.id : step1.salon.id,
      workDate: step1.date,
      salonBelong: step1.salon.id,
      salonBelongName: step1.salon.salonName,
      enrollData: resultEnrollData.map((item) => {
        if ([1, 2].includes(item.departmentId) && (!item?.select_parent_group || !item?.select_child_group)) flag = true
        return {
          status: item.status,
          staffId: item.id,
          staffName: item.fullName,
          departmentId: item.departmentId,
          groupId: item?.select_parent_group,
          teamId: item?.select_child_group || 0,
          workTimeId: item.selectedCheckin.id,
          hourIds: convertListTimeToString(item?.time, step1.salon.salonName)
        }
      })
    }
    if (flag) {
      notification('error', 'Lỗi', `Vui lòng chọn đủ đội lớn, đội nhỏ cho các nhân sự stylist, skinner`)
      return
    }
    if (!editAgain) {
      handleCreateTimekeeping(payload)
    } else {
      const payloadEdit = {
        ...payload,
        enrollData: payload.enrollData.filter((i) => i.hourIds && i?.status !== 'PENDING')
      }
      handleUpdateTimekeeping(payloadEdit)
    }
  }
  const handleDeleteTimekeeping = async () => {
    const checkHaveBill = listStaffEdit
      .map((i) => {
        return step1.staff.find((j) => j.id === Number(i))
      })
      .filter((z) => z?.haveBill)
      .map((k) => k.id)
      .join(',')
    if (checkHaveBill && role === 'SM') {
      notification('error', 'Thất bại', `Nhân sự có id ${checkHaveBill} đã có bill không thể xóa!`)
      return
    }

    const payload: PayloadDeleteTimekeeping = {
      salonId: step1.salon?.id,
      workDate: step1.date,
      listStaff: [...listStaffEdit]
    }
    setLoadingButton(true)
    const res = await deleteTimeKeeping(payload)
    setLoadingButton(false)

    notification(
      res.data.statusCode === 0 ? 'success' : 'error',
      res.data.statusCode === 0 ? 'Thành công' : 'Thất bại',
      res.data.message
    )
    if (res.data.statusCode === 0) {
      navigate('/timekeeping/checked?sync=true')
    }
  }
  const handleOpenModal = () => {
    if (!editAgain) {
      setIsModalOpen(true)
    } else {
      setIsModalOpen(true)
    }
  }
  const validateStaffDepartment = async () => {
    let flag = false
    listStaffAdd.list.forEach((staff) => {
      if ([1, 2].includes(staff?.departmentId) && (!staff?.select_child_group || !staff.select_parent_group)) {
        flag = true
      }
    })
    if (flag) {
      notification('error', 'Lỗi', `Vui lòng chọn đủ đội lớn, đội nhỏ cho các nhân sự stylist, skinner`)
      return
    }
    try {
      const listHour = await getListHourTimekeeping({
        salonId: step1.type ? step1.salonSwitch.id : step1.salon.id,
        workTimeId: listStaffAdd?.workTime?.id
      })
      if (listHour.status === 200) {
        dispatch(handleAddStaffStep2(listHour.data.data))
        setTabKey('0')
      }
    } catch (error) {
      console.log(error)
      notification('error', 'Lỗi', `Có lỗi xảy ra`)
    }
  }
  const handleSubmit = () => {
    if (tabKey === '0') {
      listStaffEdit.length === 0 ? (editAgain ? setIsModalEditOpen(true) : submitDataToEnd()) : handleOpenModal()
    } else {
      listStaffAdd.list.length === 0
        ? editAgain
          ? setIsModalEditOpen(true)
          : submitDataToEnd()
        : validateStaffDepartment()
    }
  }
  const renderTextButton = () => {
    return type === 'ADD'
      ? listStaffAdd.list.length === 0
        ? !step1.type
          ? !editAgain
            ? 'Hoàn tất chấm công'
            : 'Sửa chấm công'
          : 'Chuyển nhân sự'
        : 'Thêm'
      : listStaffEdit.length === 0
      ? !step1.type
        ? !editAgain
          ? 'Hoàn tất chấm công'
          : 'Sửa chấm công'
        : 'Chuyển nhân sự'
      : 'Xóa'
  }

  return (
    <>
      <div className='checkout_footer footerCheckin'>
        <div className='wrap_content'>
          <Button
            type='primary'
            style={{
              backgroundColor: '#F5F5F5',
              color: '#434343',
              boxShadow: 'unset',
              pointerEvents: loadingButton ? 'none' : 'auto'
            }}
            onClick={() => {
              if (type === 'EDIT' && listStaffEdit.length > 0) {
                form.resetFields()
                dispatch(resetStaffSelect())
              } else {
                dispatch(resetStore())
                navigate(!editAgain ? '/timekeeping/step-1' : '/timekeeping/checked?sync=true')
                localStorage.setItem('STAFF_EDIT', '')
              }
            }}
          >
            {type === 'EDIT' && listStaffEdit.length > 0 ? 'Hủy' : 'Thoát'}
          </Button>
          <button
            className={`fill_button buttonLikeAnt ${renderTextButton() === 'Xóa' && 'buttonLikeAnt_danger'}`}
            onClick={async () => {
              if (!hasEditPermission) {
                notification('error', 'Thất bại', `Bạn không có quyền chỉnh sửa thông tin chấm công ngày ${step1.date}`)
                return
              }
              const store = localStorage.getItem('STAFF_EDIT')
              if (store) await dispatch(updateChangeStaffFromStogare())
              await handleSubmit()
            }}
            style={{ pointerEvents: loadingButton ? 'none' : 'auto' }}
          >
            {loadingButton ? <Spin indicator={antIcon} /> : renderTextButton()}
          </button>
        </div>
      </div>
      <Modal
        centered
        title='Xác nhận'
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button key='back' onClick={() => setIsModalOpen(false)}>
            Hủy
          </Button>,
          <Button
            key='submit'
            type='primary'
            onClick={() => {
              editAgain ? handleDeleteTimekeeping() : dispatch(handleEditRemoveStaffStep2())
              setIsModalOpen(false)
            }}
          >
            Đồng ý
          </Button>
        ]}
      >
        <p className='p_ant-modal-body'> Bạn muốn xóa bản chấm công này?</p>
      </Modal>
      <Modal
        centered
        title={<p style={{ margin: '5px 0 0 5px' }}>Xác nhận</p>}
        open={isModalEditOpen}
        onOk={() => setIsModalEditOpen(false)}
        onCancel={() => setIsModalEditOpen(false)}
        footer={[
          <Button key='back' onClick={() => setIsModalEditOpen(false)}>
            Hủy
          </Button>,
          <Button
            key='submit'
            type='primary'
            onClick={() => {
              submitDataToEnd()
              setIsModalEditOpen(false)
            }}
          >
            Đồng ý
          </Button>
        ]}
      >
        <p className='p_ant-modal-body'>
          {isPastDate(step1.date)
            ? `Nếu bạn sửa ngày ${paramSearch.date} bạn cần sửa từ ngày ${dayjs(paramSearch.date)
                .add(1, 'day')
                .format('YYYY-MM-DD')} đến cuối tháng để tổng hợp đúng giờ công, ngày tăng ca`
            : 'Xác nhận cập nhập chấm công'}
        </p>
      </Modal>
    </>
  )
}

export default React.memo(FooterCheckin)
