import React, { useEffect, useState } from 'react'
import { Form, Select, Space, Spin } from 'antd'
import { LIST_CHILD_GROUP, LIST_PARENT_GROUP } from '../constant'
import { TimeStaffType } from '../type'
import CheckableTag from 'antd/es/tag/CheckableTag'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { fetchDetailTimekeepingEdit, updateTeamGroupStaffEdit } from '../reducers/checkin'
import { getDetailTimeKeeping } from '../api'
import { useLocation } from 'react-router-dom'
import { validateInputSelectBox } from 'utils/helpers/util'

type propsMember = {
  index: number
  form: any
}
const MemberEditComponent = ({ index, form }: propsMember) => {
  const editAgain = useLocation().pathname.includes('/timekeeping/edit')

  const dispatch = useAppDispatch()
  const { staff, salon, date }: any = useAppSelector((state) => state.checkin.step1)
  const { role } = useAppSelector((state) => state.user)

  const [staffTime, setStaffTime] = useState(staff[index]?.time)
  const [staffEdit, setStaffEdit] = useState({ ...staff[index] })
  const [tagLoading, setTagLoading] = useState(false)
  const [optionChildGroup, setOptionChildGroup] = useState(
    LIST_CHILD_GROUP.reduce((a, b) => [...a, ...b]).map((i) => ({ label: i, value: i }))
  )

  const handleChangeStaff = (tag: TimeStaffType, checked: boolean) => {
    const newStaffTime = staffTime.map((i: TimeStaffType) => {
      if (i.hourId === tag.hourId)
        return {
          ...tag,
          isActive: checked
        }
      return i
    })
    setStaffTime(newStaffTime)
    setStaffEdit({ ...staffEdit, time: newStaffTime })
  }

  const handleFetchDetailTimekeeping = async () => {
    setTagLoading(true)
    if (salon?.id && staff[index]?.id && date) {
      setTagLoading(true)
      const { data } = await getDetailTimeKeeping({
        salonId: salon?.id,
        staffId: staff[index]?.id,
        workDate: date
      })
      setTagLoading(false)
      if (data.statusCode === 0) {
        const time = data.data.hours.map((i) => ({
          ...i,
          hourName: i.hour,
          isActive: i.iskeeping
        }))
        setStaffEdit({ ...staffEdit, time: time })
        dispatch(fetchDetailTimekeepingEdit({ index, time: { ...staff[index], time } }))
      }
    }
    setTagLoading(false)
  }

  useEffect(() => {
    setStaffTime(staff[index]?.time)
  }, [staff[index]])

  useEffect(() => {
    if (editAgain && !staff[index]?.time) {
      handleFetchDetailTimekeeping()
    }
  }, [])
  const handleStaffEditToLocalStorage = (staffEdit) => {
    if (staffEdit?.time) {
      const staffEditStorage: any = localStorage.getItem('STAFF_EDIT')
      if (!staffEditStorage) {
        localStorage.setItem('STAFF_EDIT', JSON.stringify([staffEdit]))
      } else {
        const store = JSON.parse(staffEditStorage) || []
        const staff = store.find((i) => i.id === staffEdit.id)
        if (staff?.id) {
          localStorage.setItem('STAFF_EDIT', JSON.stringify(store.map((i) => (i.id !== staff.id ? i : staffEdit))))
        } else {
          localStorage.setItem('STAFF_EDIT', JSON.stringify([...store, staffEdit]))
        }
      }
    }
  }
  useEffect(() => {
    handleStaffEditToLocalStorage(staffEdit)
  }, [staffEdit])
  const renderTagStatus = (tag: TimeStaffType) => {
    if (tag.isBookStylist && tag.isMakeBill) return 'KB'
    if (tag.isBookStylist) return 'K'
    if (tag.isMakeBill) return 'B'
    return ''
  }
  const renderTagBackground = (tag: TimeStaffType) => {
    if (salon.salonName !== tag.salonName && tag?.salonName && tag?.iskeeping) return 'tag_support'
    if (tag?.isOverTime && tag.isActive) return 'tag_overTime'
    return ''
  }
  useEffect(() => {
    validateInputSelectBox()
    form.setFieldsValue({
      [index]: {
        select_parent_group: staff[index]?.select_parent_group,
        select_child_group: staff[index]?.select_child_group
      }
    })
  }, [])
  return (
    <div className='staff_edit_tags' style={{ marginTop: role === 'ADMIN' && editAgain ? 15 : 0 }}>
      <div className='text_label'>Đã chấm công: {staff[index]?.selectedCheckin?.name}</div>
      <div className='wrap_form_item' style={{ display: 'block' }}>
        {(staff[index].departmentId === 1 || staff[index].departmentId === 2) && (
          <div className='wrap_select_staff'>
            <div className='select_parent_group'>
              Đội lớn
              <Form.Item style={{ marginBottom: 0 }} name={[index, 'select_parent_group']}>
                <Select
                  disabled={editAgain && staff[index].status === 'PENDING'}
                  className='select_team_goup'
                  onChange={(value: number) => {
                    const payload = { ...staffEdit, select_parent_group: value, select_child_group: undefined }
                    form.setFieldsValue({
                      [index]: { select_child_group: null }
                    })
                    setStaffEdit(payload)
                    if (value) {
                      setOptionChildGroup(LIST_CHILD_GROUP[value].map((i) => ({ label: i, value: i })))
                    }
                  }}
                  options={LIST_PARENT_GROUP.map((item) => ({
                    label: item,
                    value: item
                  }))}
                  defaultValue={staff[index]?.select_parent_group}
                />
              </Form.Item>
            </div>
            <div className='select_child_group'>
              Đội nhỏ
              <Form.Item style={{ marginBottom: 0 }} name={[index, 'select_child_group']}>
                <Select
                  disabled={editAgain && staff[index].status === 'PENDING'}
                  onChange={(e) => {
                    const payload = {
                      ...staffEdit,
                      select_child_group: e,
                      select_parent_group: e ? Number(e.toString()[0]) : undefined
                    }
                    if (e) {
                      form.setFieldsValue({
                        [index]: { select_parent_group: Number(e.toString()[0]) }
                      })
                    }
                    setStaffEdit(payload)
                    handleStaffEditToLocalStorage(payload)
                    dispatch(
                      updateTeamGroupStaffEdit({
                        ...staffEdit,
                        select_child_group: e,
                        select_parent_group: e ? Number(e.toString()[0]) : undefined
                      })
                    )
                  }}
                  className='select_team_goup selectBoxValidate'
                  options={optionChildGroup}
                  defaultValue={staff[index]?.select_child_group}
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    (option?.value.toString() ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </div>
          </div>
        )}

        <div>
          <Spin spinning={tagLoading}>
            <Space className='switchEmployee_Space'>
              {(staffEdit?.time || []).map((tag: TimeStaffType) => (
                <CheckableTag
                  className={`tag ${renderTagBackground(tag)} 
                  ${
                    // [''].includes(renderTagStatus(tag)) && 
                    // tag.iskeeping ? 'tag_support' : ''
                    ''
                  }`}
                  key={tag.hourId}
                  checked={tag.isActive}
                  onChange={(checked) => {
                    if (editAgain && staff[index].status === 'PENDING') return
                    handleChangeStaff(tag, checked)
                  }}
                >
                  <div className='item_tag'>
                    <div className='tag_status'>{renderTagStatus(tag)}</div>
                    {salon.salonName !== tag.salonName && tag?.iskeeping && (
                      <span className='tag_salon_support'>{tag.salonName}</span>
                    )}
                    <span className='item_tag_top'>{tag.hourName}</span>
                  </div>
                </CheckableTag>
              ))}
            </Space>
          </Spin>
        </div>
        <div className='workingTime_description'>B: Đã in bill, K: Khách book, KB: Khách book đã in bill</div>
      </div>
    </div>
  )
}
export default React.memo(MemberEditComponent)
