import React, { useMemo } from 'react'
import { Button } from 'antd'
import error from 'images/error.png'
import pending from 'images/pending.png'
import success from 'images/success.png'
import { useMediaQuery } from 'react-responsive'
const STATUS = { PENDING: 'Đang xử lý', SUCCESS: 'Đã chấm', ERROR: 'Lỗi' }
const STATUS_ICON = {
  PENDING: pending,
  SUCCESS: success,
  ERROR: error
}
export const STATUS_COLOR = {
  PENDING: '#1890FF',
  SUCCESS: '#11B14B',
  ERROR: '#FF4D4F'
}
const STATUS_BACKGROUND = {
  PENDING: '#E6F7FF',
  SUCCESS: '#F6FFED',
  ERROR: '#FFF1F0'
}
type props = {
  status: 'PENDING' | 'SUCCESS' | 'ERROR'
  onClick?: (params: any) => void
}
export default function ButtonStatus({ status, onClick }: props) {
  const isMobile = useMemo(() => window.innerWidth <= 650, [window.innerWidth])

  return (
    <div className='wrap_ButtonStatus'>
      <Button
        className='ButtonStatus'
        style={{
          backgroundColor: STATUS_BACKGROUND[status],
          color: STATUS_COLOR[status]
        }}
        onClick={onClick}
      >
        <img width={14} height={14} src={STATUS_ICON[status]} alt='button' />
        {isMobile && status === 'SUCCESS' ? (
          <span className='isNotMobile' style={{ marginLeft: 5 }}>{STATUS[status]}</span>
        ) : (
          <span style={{ marginLeft: 5 }}>{STATUS[status]}</span>
        )}
      </Button>
    </div>
  )
}
