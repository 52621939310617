/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react'
import { Button, DatePicker, Form, Modal, Radio, RadioChangeEvent, Select, Space, Spin, Steps, Switch } from 'antd'
import '../style/CheckinPage.scss'
import { CheckOutlined, CloseOutlined, UpCircleFilled } from '@ant-design/icons'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import CheckableTag from 'antd/es/tag/CheckableTag'
import ListMember from '../components/ListMember'
import { resetStaffSelectStep1, resetStore, setParamSearch, setStep1 } from '../reducers/checkin'
import store, { useAppDispatch, useAppSelector } from 'redux/store'
import { useNavigate } from 'react-router-dom'
import { optionListSalon, optionWorkTime } from '../type'
import SwitchStaff from '../components/SwitchStaff'
import { handleFetchDepartment, handleFetchTimekeeping, handleFetchWorkTime } from '../hook/fetchData'
import { copyTimekeeping, getActionTimekeeping, getListHourTimekeeping } from '../api'
import { AppContext } from 'layouts/DashboardLayout'
import { antIcon } from '../components/FooterCheckin'
import { ACTION_TIMEKEEPING } from '../constant'

function CheckinPage1() {
  const dispatch = useAppDispatch()
  const {
    paramSearch,
    resource: { workTime, listStaff, department, allSalon }
  } = useAppSelector((state) => state.checkin)
  const {
    role,
    userInfo: { listSalon }
  } = useAppSelector((state) => state.user)
  const { notification } = useContext(AppContext)
  const [loadingButton, setLoadingButton] = useState(false)
  const [filterStaff, setFilterStaff] = useState([])

  const countStaffSelected = useRef<number>(0)
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const checkoutContainer = useRef<HTMLDivElement>()
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [dateCopy, setDateCopy] = useState<string>('')
  const [listDepartment, setListDepartment] = useState<optionListSalon[]>([])
  const [salonSelect, setSalonSelect] = useState<number>()
  const [salonSwitch, setSalonSwitch] = useState<number>()
  const [listMemberActive, setListMemberActive] = useState<any>([])
  const [listMemberFilter, setListMemberFilter] = useState<any>([])

  const [listWorkTimeOptions, setListWorkTimeOptions] = useState<optionWorkTime[]>([])
  const [typeCheckin, setTypeCheckin] = useState()
  const [switchEmployee, setSwitchEmployee] = useState(false)
  const [selectedTags, setSelectedTags] = useState<optionWorkTime | undefined>()
  const [selectedCheckin, setSelectedCheckin] = useState<optionWorkTime | undefined>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [typeCopy, setTypeCopy] = useState(false)

  const handleChangeStaff = (tag: optionWorkTime, checked: boolean) => {
    const nextSelectedTags = checked ? tag : undefined
    setSelectedTags(nextSelectedTags)
  }

  const handleChangeCheckin = (tag: optionWorkTime, checked: boolean) => {
    const nextSelectedTags = checked ? tag : undefined
    setSelectedCheckin(nextSelectedTags)
  }

  const handleDisableButtonSubmit = () => {
    if (!salonSelect) return true
    if (switchEmployee) {
      if (!selectedTags?.id || !salonSwitch) return true
    } else {
      if (typeCheckin === 1) {
        if (!dateCopy) return true
      } else {
        if (!selectedCheckin?.id) return true
      }
    }
    return false
  }

  const onFinish = async () => {
    const { selectStaffAdd } = store.getState().checkin.step1
    let flag = false
    if (switchEmployee || typeCheckin === 2) {
      const listStaftValue = []
      const selectedCheckinValue = !switchEmployee ? selectedCheckin : selectedTags
      const listHour = await getListHourTimekeeping({
        salonId: switchEmployee ? salonSwitch : salonSelect,
        workTimeId: selectedCheckinValue.id
      })
      if (listHour.status === 200) {
        selectStaffAdd
          .filter((i) => i.salonId === salonSelect)
          .forEach((staff) => {
            const newStaff = { ...staff }
            if (staff?.checkbox_group === true) {
              if ([1, 2].includes(staff.departmentId) && (!staff?.select_child_group || !staff?.select_parent_group)) {
                flag = true
              }
              if (flag) return
              newStaff.time = listHour.data.data
              newStaff.selectedCheckin = selectedCheckinValue
              listStaftValue.push(newStaff)
            }
          })
        if (flag) {
          notification('error', 'Lỗi', `Vui lòng chọn đủ đội lớn, đội nhỏ cho các nhân sự stylist, skinner`)
          return
        }
        if (listStaftValue.length > 0) {
          dispatch(
            setStep1({
              staff: listStaftValue,
              date,
              salon: allSalon?.find((i) => i.id === salonSelect),
              salonSwitch: allSalon?.find((i) => i.id === salonSwitch),
              type: switchEmployee,
              workTime: [!switchEmployee ? selectedCheckin : selectedTags]
            })
          )
          navigate('/timekeeping/step-2')
          localStorage.setItem('STAFF_EDIT', '')
        } else {
          notification('error', 'Lỗi', 'Chưa chọn nhân sự nào')
        }
      }
    } else {
      setLoadingButton(true)
      try {
        const res = await copyTimekeeping({
          salonId: salonSelect,
          workDate: date,
          copyDate: dateCopy
        })
        notification('success', 'Thành công', res.data.message)
        dispatch(setParamSearch({ ...paramSearch, date, salon: salonSelect }))
        if (typeCopy) navigate('/timekeeping/checked?sync=true')
      } catch (error) {
        notification('error', 'Thất bại', error.response.data.message)
      }
      setLoadingButton(false)
    }
  }

  const handleFetchTimekeepingComponent = async () => {
    if (salonSelect && date) {
      await handleFetchTimekeeping({ salonId: salonSelect, workDate: date }, dispatch, notification)
    }
  }

  const disabledDate = (current: Dayjs | null): boolean => {
    const today: Dayjs = dayjs()
    if (role === 'SM') {
      const twoDaysAgo: Dayjs = dayjs().subtract(2, 'day')
      return (!!current && current.isBefore(twoDaysAgo)) || current.isAfter(today.add(1, 'month'))
    } else {
      if (today.date() <= 10) {
        return (
          (!!current && current.isBefore(today.subtract(2, 'month').endOf('month'))) ||
          current.isAfter(today.add(1, 'month'))
        )
      }
      return (!!current && current.isBefore(today.startOf('month'))) || current.isAfter(today.add(1, 'month'))
    }
  }
  useEffect(() => {
    handleFetchTimekeepingComponent()
  }, [salonSelect, date])

  useEffect(() => {
    dispatch(resetStore())
    handleFetchDepartment(dispatch)
    handleFetchWorkTime(dispatch)
  }, [])
  useEffect(() => {
    setListMemberActive(listStaff)
  }, [listStaff])
  useEffect(() => {
    setListMemberFilter(listMemberActive.filter((staff) => filterStaff.includes(staff.departmentId)))
  }, [filterStaff])
  useEffect(() => {
    if (listSalon?.length > 0) {
      setListWorkTimeOptions(workTime)
      setListDepartment(
        department.map((i) => ({
          value: i.id,
          label: i.displayName
        }))
      )
    }
  }, [workTime, department])
  useEffect(() => {
    if (listSalon?.length === 1) {
      setSalonSelect(listSalon[0].id)
    }
  }, [listSalon])

  return (
    <div className='checkout_container' id='overflowHidden' ref={checkoutContainer}>
      <Form
        form={form}
        name='dynamic_form_complex'
        onFinish={onFinish}
        style={{ paddingBottom: 100 }}
        autoComplete='off'
      >
        <div className='checkout_header'>
          <div className='checkout_header__title'>Tạo chấm công</div>
          <div className='steps_header'>
            <Steps
              current={0}
              style={{ fontSize: 14 }}
              items={[
                {
                  title: 'Tạo mới'
                },
                {
                  title: 'Chỉnh sửa'
                },
                {
                  title: 'Kết quả chấm công'
                }
              ]}
            />
          </div>
          <div className='step1_title'>BƯỚC 1: TẠO MỚI</div>
        </div>

        <div className='checkout_content'>
          <div className='step1'>
            <div className='header_content'>
              <div className='text_label'>chọn ngày chấm và salon</div>
              <DatePicker
                className='select_style removeIcon'
                onChange={(_, dateString) => setDate(dateString)}
                defaultValue={dayjs(date)}
                format={'YYYY-MM-DD'}
                allowClear={false}
                disabledDate={disabledDate}
              />
              {listSalon?.length > 0 && (
                <Select
                  value={salonSelect}
                  className='select_style'
                  onChange={(value) => {
                    dispatch(resetStaffSelectStep1())
                    setSalonSelect(value)
                  }}
                  fieldNames={{ value: 'id', label: 'salonName' }}
                  disabled={listSalon?.length === 1}
                  options={listSalon?.filter((i) => i?.id !== salonSwitch)}
                  placeholder='Chọn Salon'
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    (option?.salonName ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                />
              )}
            </div>
            <div className='main_content'>
              <div className='switch_emmployee'>
                <Switch
                  onChange={(checked: boolean) => {
                    setSwitchEmployee(checked)
                    form.resetFields()
                    dispatch(resetStaffSelectStep1())
                  }}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
                <div style={{ marginLeft: 10 }}>Chuyển nhân sự</div>
              </div>
              <div>
                {switchEmployee ? (
                  <SwitchStaff
                    listWorkTimeOptions={listWorkTimeOptions}
                    listDepartment={listDepartment}
                    salonSelect={salonSelect}
                    salonSwitch={salonSwitch}
                    selectedTags={selectedTags}
                    handleChangeStaff={handleChangeStaff}
                    checkoutContainer={checkoutContainer.current}
                    form={form}
                    setSalonSwitch={setSalonSwitch}
                    isModalOpen={isModalOpen}
                  />
                ) : (
                  <div className='checkin'>
                    <div className='text_label'>CHỌN CÁCH chấm công</div>
                    <Radio.Group
                      style={{ marginTop: 5 }}
                      onChange={(e: RadioChangeEvent) => setTypeCheckin(e.target.value)}
                      value={typeCheckin}
                    >
                      <Radio value={1}>Copy chấm công</Radio>
                      <Radio value={2}>Chấm thủ công</Radio>
                    </Radio.Group>
                    {typeCheckin === 1 && (
                      <>
                        <DatePicker
                          onChange={(_, dateString) => setDateCopy(dateString)}
                          className='select_style'
                          format={'YYYY-MM-DD'}
                          placeholder='Chọn ngày copy chấm công'
                        />
                      </>
                    )}
                    {typeCheckin === 2 && (
                      <>
                        <div style={{ marginTop: 10 }} className='text_label_Oswald'>
                          chọn ca
                        </div>
                        <Space className='switchEmployee_Space'>
                          {(listWorkTimeOptions || []).map((tag: optionWorkTime) => (
                            <CheckableTag
                              className='tag'
                              key={tag.id}
                              checked={selectedCheckin?.id === tag?.id}
                              onChange={(checked) => {
                                handleChangeCheckin(tag, checked)
                              }}
                            >
                              <div className='item_tag'>
                                <span className='item_tag_top'>{tag.name}</span>
                                <span className='item_tag_bottom'>{`${tag.startTime} - ${tag.endTime}`}</span>
                              </div>
                            </CheckableTag>
                          ))}
                        </Space>
                        <Select
                          mode='multiple'
                          className='select_style'
                          allowClear
                          onChange={(value) => {
                            setFilterStaff(value)
                            if (value.length > 0)
                              setListMemberActive(listStaff.filter((staff) => value.includes(staff.departmentId)))
                            else setListMemberActive(listStaff)
                          }}
                          options={listDepartment}
                          placeholder='Chọn tất cả bộ phận'
                          showSearch={false}
                          optionFilterProp='children'
                        />
                        {selectedCheckin && salonSelect && (
                          <ListMember
                            checkoutContainer={checkoutContainer.current}
                            listMemberActive={filterStaff.length > 0 ? listMemberFilter : listMemberActive}
                            form={form}
                          />
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='checkout_footer'>
          <div className='wrap_content'>
            {typeCheckin || salonSwitch !== undefined ? (
              typeCheckin === 2 || switchEmployee ? (
                <Button
                  style={{ pointerEvents: loadingButton ? 'none' : 'auto' }}
                  type='primary'
                  disabled={handleDisableButtonSubmit()}
                  onClick={() => {
                    if (switchEmployee) {
                      const fieldsValue = store.getState().checkin.step1.selectStaffAdd
                      countStaffSelected.current = Object.keys(fieldsValue).filter(
                        (i) => fieldsValue[i].checkbox_group
                      ).length
                      if (!countStaffSelected.current) notification('error', 'Lỗi', 'Chưa chọn nhân sự nào')
                      else {
                        setIsModalOpen(true)
                      }
                    } else {
                      form.submit()
                    }
                  }}
                >
                  {loadingButton ? <Spin indicator={antIcon} /> : 'Tiếp tục'}
                </Button>
              ) : (
                <>
                  <Button
                    style={{ pointerEvents: loadingButton ? 'none' : 'auto' }}
                    disabled={handleDisableButtonSubmit()}
                    type='primary'
                    onClick={() => {
                      form.submit()
                      setTypeCopy(false)
                    }}
                  >
                    {loadingButton && !typeCopy ? <Spin indicator={antIcon} /> : 'Copy'}
                  </Button>
                  <Button
                    style={{ pointerEvents: loadingButton ? 'none' : 'auto' }}
                    disabled={handleDisableButtonSubmit()}
                    type='primary'
                    onClick={() => {
                      form.submit()
                      setTypeCopy(true)
                    }}
                  >
                    {loadingButton && typeCopy ? <Spin indicator={antIcon} /> : 'Copy và xem ngay'}
                  </Button>
                </>
              )
            ) : (
              ''
            )}
          </div>
        </div>
      </Form>
      <Modal
        centered
        title={<p style={{ paddingLeft: 0, margin: '5px 0 0 5px' }}>Xác nhận</p>}
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button key='back' onClick={() => setIsModalOpen(false)}>
            Hủy
          </Button>,
          <Button key='submit' type='primary' onClick={() => form.submit()}>
            Đồng ý
          </Button>
        ]}
      >
        <p className='p_ant-modal-body'>
          {`Bạn có chắc chắn muốn chuyển ${countStaffSelected.current} nhân sự từ salon ${
            allSalon?.find((i) => i.id === salonSelect)?.displayName
          } sang salon ${allSalon?.find((i) => i.id === salonSwitch)?.displayName} không?`}
        </p>
      </Modal>
      <Button
        type='link'
        onClick={() => {
          checkoutContainer.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        }}
        className='scrollToTop'
      >
        <UpCircleFilled style={{ fontSize: 25 }} />
      </Button>
    </div>
  )
}

export default React.memo(CheckinPage1)
