import React, { useEffect, useMemo, useState } from 'react'
import { useAppSelector } from 'redux/store'
import { uniq, uniqBy } from 'lodash'
import ListPanelStaffFilter from './ListPanelStaffFilter'
import FooterCheckin from './FooterCheckin'
import { FormInstance } from 'antd'
import { removeVietnameseDiacritics, validateInputSelectBox } from 'utils/helpers/util'

type propsListMember = {
  form: FormInstance<any>
  selectGroup: number
  tabKey?: string
  selectedDepartment: number
  isFooter?: boolean
  isEdit?: boolean
  selectStatus?: any
  isSupport?: number
  checkinContainer?: HTMLDivElement
  searchName?: string
}
const Step2ListStaffEdit = ({
  form,
  selectGroup,
  tabKey,
  selectedDepartment,
  isFooter = true,
  isEdit,
  selectStatus,
  isSupport = 0,
  checkinContainer,
  searchName
}: propsListMember) => {
  const isCreate = window.location.pathname.includes('/timekeeping/step-2')
  const { staff }: any = useAppSelector((state) => state.checkin.step1)
  const [staffFilter, setStaffFilter] = useState([])
  const listStaffByTeam = useMemo(
    () => uniq(staffFilter.map((item) => item.select_child_group || 0)).sort(),
    [staffFilter]
  )
  const listStaffByWorkingTime = useMemo(
    () =>
      uniqBy(
        staffFilter.map((item) => item.selectedCheckin),
        'id'
      ).sort((a, b) => a.id - b.id),
    [staffFilter]
  )

  useEffect(() => {
    if (searchName) {
      setStaffFilter(
        staff.filter(
          (item) =>
            (removeVietnameseDiacritics(item?.fullName) || '').toLowerCase().includes(searchName) ||
            item.id.toString().includes(searchName)
        )
      )
    } else setStaffFilter(staff)
  }, [searchName, staff])

  useEffect(() => {
    validateInputSelectBox()
  }, [tabKey])
  return (
    <div className='edit_staff'>
      {(selectGroup === 0 ? listStaffByWorkingTime : listStaffByTeam).map((team: any) => {
        let listStaff = (
          selectedDepartment ? staff.filter((i) => i.departmentId === selectedDepartment) : staff
        ).filter((i) => {
          if (selectGroup === 0) {
            return i.selectedCheckin?.id === team?.id
          } else {
            if (team === 0) return !i.select_child_group
            return i?.select_child_group === team
          }
        })

        listStaff = selectStatus ? listStaff.filter((i) => i?.status === selectStatus) : [...listStaff]
        listStaff = isSupport ? listStaff.filter((i) => i?.isSupport === isSupport) : [...listStaff]
        listStaff =
          searchName && !isCreate
            ? listStaff.filter(
                (staff) =>
                  removeVietnameseDiacritics(staff?.fullName || '')
                    .toLowerCase()
                    .includes(searchName) || staff.id.toString().includes(searchName)
              ) || []
            : listStaff || []
        return (
          listStaff.length > 0 && (
            <ListPanelStaffFilter
              key={selectGroup === 0 ? team?.id : team}
              form={form}
              listStaff={listStaff}
              team={team}
              selectGroup={selectGroup}
              isEdit={isEdit}
              checkinContainer={checkinContainer}
            />
          )
        )
      })}
      {isFooter && <FooterCheckin type={'EDIT'} tabKey={tabKey} form={form} />}
    </div>
  )
}
export default React.memo(Step2ListStaffEdit)
