import React, { useEffect, useMemo, useRef } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { uniq, uniqBy } from 'lodash'
import FooterCheckin from './FooterCheckin'
import CheckedListPanelStaffFilter from './CheckedListPanelStaffFilter'
import { fetchListTimekeeping } from '../reducers/checkin'

type propsListMember = {
  form: any
  selectGroup: any
  tabKey?: string
  selectedDepartment: number
  isFooter?: boolean
  isEdit?: boolean
  selectStatus?: any
  isSupport?: number
}
const Step3ListStaff = ({
  form,
  selectGroup,
  tabKey,
  selectedDepartment,
  isFooter = true,
  isEdit,
  selectStatus,
  isSupport
}: propsListMember) => {
  const dispatch = useAppDispatch()
  const { listStaffChecked }: any = useAppSelector((state) => state.checkin.resource)
  const listStaff_view = useRef([])
  useEffect(() => {
    dispatch(fetchListTimekeeping(listStaffChecked.filter((i) => i.isSupport === isSupport)))
  }, [isSupport, listStaffChecked])

  const listStaffByTeam = useMemo(
    () => uniq(listStaffChecked.map((item) => item.select_child_group || 0)).sort(),
    [listStaffChecked]
  )
  const listStaffByWorkingTime = useMemo(
    () =>
      uniqBy(
        listStaffChecked.map((item) => item.selectedCheckin),
        'id'
      ).sort((a, b) => a.id - b.id),
    [listStaffChecked]
  )

  return (
    <div className='edit_staff'>
      {(selectGroup === 0 ? listStaffByWorkingTime : listStaffByTeam).map((team: any, index: number) => {
        if (index === 0) listStaff_view.current = []
        const listStaff_filter_department = (
          selectedDepartment ? listStaffChecked.filter((i) => i.departmentId === selectedDepartment) : listStaffChecked
        ).filter((i) => {
          if (selectGroup === 0) {
            return i.selectedCheckin?.id === team?.id
          } else {
            if (team === 0) return !i.select_child_group
            return i?.select_child_group === team
          }
        })

        const listStaff_filter_status = selectStatus
          ? listStaff_filter_department.filter((i) => i?.status === selectStatus)
          : [...listStaff_filter_department]
        const listStaff_filter_view =
          isSupport !== undefined
            ? listStaff_filter_status.filter((i) => i.isSupport === isSupport)
            : [...listStaff_filter_status]
        if (listStaff_filter_view.length > 0) listStaff_view.current.push(...listStaff_filter_view)
        return (
          listStaff_filter_view.length > 0 && (
            <CheckedListPanelStaffFilter
              key={selectGroup === 0 ? team?.id : team}
              form={form}
              listStaff={listStaff_filter_view}
              team={team}
              selectGroup={selectGroup}
              isEdit={isEdit}
              isSupport={isSupport}
            />
          )
        )
      })}
      {isFooter && <FooterCheckin type={'EDIT'} tabKey={tabKey} form={form} />}
    </div>
  )
}
export default React.memo(Step3ListStaff)
