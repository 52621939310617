import React, { useState, useEffect, useRef } from 'react'
import { Space, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import MemberComponent from './MemberComponent'
import CheckableTag from 'antd/es/tag/CheckableTag'
import { StaffType, optionWorkTime } from '../type'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { setListStaffAddStep2WorkTime } from '../reducers/checkin'
import FooterCheckin from './FooterCheckin'
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

type propsListMember = {
  listMemberActive: StaffType[]
  checkinContainer: HTMLDivElement
  form: any
  tabKey: string
  setTabKey?: any
}
const Step2ListStaffAdd = ({ listMemberActive, checkinContainer, form, tabKey, setTabKey }: propsListMember) => {
  const page = useRef(1)
  const { resource }: any = useAppSelector((state) => state.checkin)
  const dispatch = useAppDispatch()

  const [listMemberRender, setListMemberRender] = useState<StaffType[]>([])
  const [selectedTags, setSelectedTags] = useState<optionWorkTime | undefined>()

  function handleScroll() {
    if (checkinContainer) {
      const { scrollTop, scrollHeight, clientHeight } = checkinContainer
      if (scrollTop + clientHeight > scrollHeight * 0.9) {
        page.current += 1
        setListMemberRender(listMemberActive.slice(0, (page.current + 1) * 10))
      }
    }
  }
  const handleChangeStaff = (tag: optionWorkTime, checked: boolean) => {
    const nextSelectedTags = checked ? tag : undefined
    setSelectedTags(nextSelectedTags)
    dispatch(setListStaffAddStep2WorkTime(nextSelectedTags))
  }
  const resetPagination = () => {
    setListMemberRender(listMemberActive.slice(0, 10))
    page.current = 1
  }

  useEffect(() => {
    resetPagination()
    // form.resetFields()
    checkinContainer.addEventListener('scroll', handleScroll)
    return () => {
      checkinContainer.removeEventListener('scroll', handleScroll)
    }
  }, [listMemberActive])

  useEffect(() => {
    resetPagination()
  }, [tabKey])
  return (
    <>
      <div className='text_label_Oswald'>chọn ca</div>
      <Space className='switchEmployee_Space'>
        {resource.workTime.map((tag: optionWorkTime) => (
          <CheckableTag
            className='tag'
            key={tag.id}
            checked={selectedTags?.id === tag?.id}
            onChange={(checked) => handleChangeStaff(tag, checked)}
          >
            <div className='item_tag'>
              <span className='item_tag_top'>{tag.name}</span>
              <span className='item_tag_bottom'>{`${tag.startTime} - ${tag.endTime}`}</span>
            </div>
          </CheckableTag>
        ))}
      </Space>
      {selectedTags &&
        listMemberRender.map((member: StaffType) => {
          return <MemberComponent key={member.id} member={member} index={member.id} form={form} />
        })}
      {selectedTags && listMemberRender.length < listMemberActive.length && (
        <div className='spinner'>
          <Spin style={{ display: 'block', margin: 'auto' }} indicator={antIcon} />
        </div>
      )}
      <FooterCheckin type={'ADD'} tabKey={tabKey} setTabKey={setTabKey} form={form} />
    </>
  )
}
export default React.memo(Step2ListStaffAdd)
