/* eslint-disable react-hooks/rules-of-hooks */
import { Collapse, Button, Modal, Table } from 'antd'
import React, { useState, useRef, useMemo, useEffect, useContext } from 'react'
import ButtonStatus, { STATUS_COLOR } from 'components/ButtonStatus'
import edit from 'images/edit.png'
import clock from 'images/clock.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { getLogTimeKeeping } from '../api'
import { SELECT_STATUS_STEP_3 } from '../constant'
import dayjs from 'dayjs'
import CheckedMemberEditComponent from './CheckedMemberEditComponent'
import { useAppSelector } from 'redux/store'
import { AppContext } from 'layouts/DashboardLayout'
import { validateEditPermission } from '../util'

const { Panel } = Collapse
type ListPanelStaffFilterProps = {
  form?: any
  listStaff?: any
  team?: any
  selectGroup?: any
  isEdit?: boolean
  isSupport?: number
}
const CheckedListPanelStaffFilter = ({
  listStaff,
  team,
  selectGroup,
  isEdit = true,
  isSupport
}: ListPanelStaffFilterProps) => {
  const { notification } = useContext(AppContext)

  const isMobile = useMemo(() => window.innerWidth <= 500, [])
  const navigate = useNavigate()
  const editAgain = useLocation().pathname === '/timekeeping/edit'
  const listStaffStore = useAppSelector((state) => state.checkin.resource.listStaffChecked)
  const { role } = useAppSelector((state) => state.user)
  const { date } = useAppSelector((state) => state.checkin.step1)
  const hasEditPermission = validateEditPermission(role, date)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState({
    title: '',
    content: '',
    status: ''
  })

  const ref = useRef<HTMLDivElement>()

  const columns = [
    {
      title: 'Thời gian',
      dataIndex: 'logDate',
      key: 'logDate',
      render: (logDate) => <div style={{ width: 85 }}>{dayjs(logDate).format('YYYY-MM-DD HH:mm:ss')}</div>
    },
    {
      title: 'Người thực hiện',
      dataIndex: 'userAction',
      key: 'userAction',
      render: (userAction) => <div style={{ width: 55 }}>{userAction}</div>
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description'
    }
  ]

  const handleHistoryTimekeeing = async (streamId: string, status: string) => {
    const data = await getLogTimeKeeping(streamId)

    if (data.data.statusCode === 200) {
      setIsModalOpen(true)

      setModalContent({
        title: SELECT_STATUS_STEP_3.find((i) => i.value === status).label,
        content: data.data.data,
        status
      })
    }
  }
  useEffect(() => {
    if (isModalOpen) {
      const modal: any = window.document.querySelector('.ant-modal')
      if (modal) {
        modal.style.top = '10% !important'
      }
    }
  }, [isModalOpen])

  return (
    <div className='CheckedListPanelStaffFilter'>
      <div className='header_list_staff_edit'>
        {selectGroup === 1
          ? team === 0
            ? `Danh sách đội khác`
            : `Danh sách đội ${team || ''}`
          : `Danh sách ca ${team?.name || ''} (${team?.startTime || ''} - ${team?.endTime || ''})`}
      </div>
      <Collapse defaultActiveKey={1} ref={ref} ghost>
        {listStaff
          .sort((a, b) => a.id - b.id)
          .sort((a, b) => a.departmentId - b.departmentId)
          .map((staff, index) => {
            return (
              <Panel
                key={`${staff.id}_${index}_${staff.salonId}`}
                header={
                  <div className='wrap_form_item'>
                    <div className='item_panel_member'>
                      <div className='fullName'>{staff.fullName}</div>
                      <div className='departmentName'>{`${staff.departmentName || 'không xác định'} - ${
                        staff.id
                      }`}</div>
                    </div>

                    <div className='wrap_Button_Status'>
                      <div className='item_expend'>
                        {staff?.numberOfWorkHourOver > 0 && (
                          <div className='overtime'>
                            {staff?.numberOfWorkHourOver} <img src={clock} alt='button' />
                          </div>
                        )}
                        {staff.select_child_group > 0 && !selectGroup ? (
                          <div className='group_team'>{`Đ${staff.select_child_group}`}</div>
                        ) : (
                          ''
                        )}
                        {selectGroup === 1 ? (
                          <div style={{ fontSize: 12 }}>
                            <div style={{ textAlign: 'center', fontWeight: 500 }}>{staff?.workTimeName}</div>
                            <div
                              style={{ fontSize: 10 }}
                            >{`${staff?.selectedCheckin.startTime} - ${staff?.selectedCheckin.endTime}`}</div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>

                      {!isEdit && staff?.status && (
                        <div className='wrap_button_action'>
                          <ButtonStatus
                            onClick={(e) => {
                              e.stopPropagation()
                              handleHistoryTimekeeing(staff.streamId, staff.status)
                            }}
                            status={staff.status}
                          />
                        </div>
                      )}
                      {staff.status !== 'PENDING' && isSupport !== 1 && (
                        <Button
                          onClick={(e) => {
                            e.stopPropagation()
                            if (!hasEditPermission) {
                              notification(
                                'error',
                                'Thất bại',
                                `Bạn không có quyền chỉnh sửa thông tin chấm công ngày ${date}`
                              )
                              return
                            } else {
                              navigate(`/timekeeping/edit/${staff.id}`)
                            }
                          }}
                          className='ButtonStatus'
                        >
                          <img src={edit} alt='button' />
                          <span className='labelEdit' style={{ marginLeft: 5 }}>{`Sửa`}</span>
                        </Button>
                      )}
                    </div>
                    {editAgain && staff?.status && (
                      <ButtonStatus
                        onClick={(e) => {
                          e.stopPropagation()
                          handleHistoryTimekeeing(staff.streamId, staff.status)
                        }}
                        status={staff.status}
                      />
                    )}
                  </div>
                }
              >
                <CheckedMemberEditComponent
                  index={listStaffStore.findIndex((i) => i.id === staff.id && i.salonId === staff.salonId)}
                  isEdit={isEdit}
                />
              </Panel>
            )
          })}
      </Collapse>
      {isModalOpen && (
        <Modal
          centered
          title={
            <div
              style={{
                fontSize: 18,
                textAlign: 'center',
                color: STATUS_COLOR[modalContent.status]
              }}
            >
              {modalContent.title}
            </div>
          }
          open={isModalOpen}
          onOk={() => setIsModalOpen(false)}
          onCancel={() => setIsModalOpen(false)}
          footer={[]}
          style={{ width: 'auto', top: 20 }}
        >
          <div className='wrap_modal'>
            {
              <Table
                style={{ fontSize: 12 }}
                pagination={{ defaultPageSize: isMobile ? 5 : 10, showSizeChanger: false }}
                dataSource={modalContent?.content as any}
                columns={columns}
              />
            }
          </div>
        </Modal>
      )}
    </div>
  )
}

export default React.memo(CheckedListPanelStaffFilter)
