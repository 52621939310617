/* eslint-disable react-hooks/rules-of-hooks */
import store from 'redux/store'
import {
  copyTimekeeping,
  getListDepartment,
  getListHourTimekeeping,
  getListStaffBySalon,
  getListTimeKeeping,
  getListWorkTime
} from '../api'
import {
  fetchListDepartment,
  fetchListStaffAll,
  fetchListStaffChecked,
  fetchListStaffTimekeeping,
  fetchListWorkTime
} from '../reducers/checkin'
import { ParamsGetListTimekeeping, ParamsHourTimekeeping, ParamsTimekeeping, PayloadCopyTimekeeping } from '../type'
import { handleFetchListTimekeeping } from './helper'

export const handleFetchDepartment = async (dispatch) => {
  if (store.getState().checkin.resource.department.length > 0) return
  try {
    const StaffType = JSON.parse(localStorage.getItem('staff-type'))
    if (StaffType) return
    const res = await getListDepartment()

    if (res.data.statusCode === 0) {
      if (!StaffType) localStorage.setItem('staff-type', JSON.stringify(res.data.data))
      dispatch(fetchListDepartment(res.data.data))
    }
  } catch (error) {
    console.log(error)
  }
}

export const handleFetchWorkTime = async (dispatch) => {
  if (store.getState().checkin.resource.workTime.length > 0) return
  try {
    const WorkTime = JSON.parse(localStorage.getItem('worktime'))
    if (WorkTime) return

    const res = await getListWorkTime()
    if (res.data.statusCode === 0) {
      const result = fetchListWorkTime(
        res.data.data.map((i) => ({
          ...i,
          endTime: i.endTime.substring(0, i.endTime.length - 3),
          startTime: i.startTime.substring(0, i.startTime.length - 3)
        }))
      )
      if (!WorkTime) localStorage.setItem('worktime', JSON.stringify(result.payload))
      dispatch(fetchListWorkTime(result.payload))
    }
  } catch (error) {
    console.log(error)
  }
}

export const handleFetchTimekeeping = async (params: ParamsTimekeeping, dispatch, notification: any) => {
  try {
    const staff_all = await getListStaffBySalon(params.salonId)
    const staff_timekeeping = await getListTimeKeeping(params)
    if (staff_timekeeping.data.statusCode === 0 && staff_all.data.statusCode === 0) {
      const list_staff_all = staff_all.data.data
      const list_staff_timekeeping = staff_timekeeping?.data?.data
      const result = list_staff_timekeeping.filter((staff) =>
        list_staff_all.find((i) => i.id === staff.staffId && staff.salonBelong === staff.salonId)
      )
      dispatch(
        fetchListStaffTimekeeping(
          list_staff_all.filter((staff) => staff.departmentId && !result.some((item) => item.staffId === staff.id))
        )
      )
      dispatch(fetchListStaffChecked({ data: result.filter((i) => i.departmentId), isRecall: false }))
      dispatch(fetchListStaffAll({ list: list_staff_all.filter((i) => i.departmentId), id: params.salonId }))
    }
  } catch (error) {
    notification('error', 'Lỗi', 'Có lỗi xảy ra')
    console.log(error)
  }
}

export const handleFetchHourTimekeeping = async (params: ParamsHourTimekeeping) => {
  if (!params?.salonId || !params?.workTimeId) return
  try {
    const res = await getListHourTimekeeping(params)

    if (res.data.statusCode === 0) {
      return res.data.data
    }
  } catch (error) {
    console.log(error)
  }

  return
}

export const handleFetchDataListTimekeeping = async (params: ParamsGetListTimekeeping, dispatch, staff, workTime) => {
  try {
    const res = await getListTimeKeeping(params)
    if (res.data.statusCode === 0) {
      handleFetchListTimekeeping(dispatch, staff, workTime, res)
    }
  } catch (error) {
    console.log(error)
  }

  return
}
export const handleFetchDataListTimekeepingEdit = async (
  params: ParamsGetListTimekeeping,
  dispatch,
  staff,
  workTime
) => {
  try {
    const res = await getListTimeKeeping(params)
    if (res.data.statusCode === 0) {
      handleFetchListTimekeeping(dispatch, staff, workTime, res, true)
    }
  } catch (error) {
    console.log(error)
  }
  return
}
export const handleCopyTimekeeping = async (params: PayloadCopyTimekeeping, notification) => {
  try {
    const res = await copyTimekeeping(params)
    notification('success', 'Thành công', res.data.message)
  } catch (error) {
    notification('error', 'Thất bại', error.response.data.message)
  }
}
