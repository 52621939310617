import { API_CHECKIN_STATUS } from '../constant'
import { fetchListStaffChecked, fetchListTimekeeping } from '../reducers/checkin'

export const handleFetchListTimekeeping = (
  dispatch,
  staff,
  workTime,
  data,
  edit = false,
  salonId = 0,
  isRecall = false
) => {
  const newData = data.data.data.map((item, index) => {
    let result = { ...item }
    if (staff[index] && staff[index].id === item.id) result = { ...staff[index] }

    return {
      ...result,
      id: result.staffId,
      select_parent_group: result.groupId,
      select_child_group: result.teamId,
      fullName: result.staffName,
      salon: {
        id: result.salonId,
        salonName: result.salonBelongName
      },
      selectedCheckin: result?.workTimeId ? workTime.find((i) => i.id === result.workTimeId) : result.selectedCheckin,
      status: API_CHECKIN_STATUS[item.status],
      isSupport: salonId ? (salonId === result.salonBelong ? (salonId === result.salonId ? 0 : 1) : 2) : undefined
    }
  })
  edit ? dispatch(fetchListTimekeeping(newData)) : dispatch(fetchListStaffChecked({ data: newData, isRecall }))
}
