import { ClockCircleOutlined, FileAddOutlined, FileDoneOutlined } from '@ant-design/icons'

export const MENUS = [
  {
    isParent: true,
    key: 'timekeeping',
    path: '',
    title: 'Chấm công',
    icon: <ClockCircleOutlined />,
    nested: [
      {
        isParent: true,
        key: '/timekeeping/step-1',
        path: '/timekeeping/step-1',
        title: 'Tạo chấm công',
        icon: <FileAddOutlined />
      },
      {
        isParent: true,
        key: '/timekeeping/checked',
        path: '/timekeeping/checked',
        title: 'Đã chấm công',
        icon: <FileDoneOutlined />
      }
    ]
  },
  {
    isParent: true,
    key: process.env.REACT_APP_URL_STAFF,
    path: process.env.REACT_APP_URL_STAFF,
    title: 'DS Nhân viên',
    icon: <FileDoneOutlined />,
    nested: []
  }
]
